import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry } from 'rxjs';
import { UserImage } from 'src/app/dto';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth.service';
import { AbstractRemoteRepository } from './abstract-remote-repository';

@Injectable({
    providedIn: 'root'
})
export class UserImageRepositoryService extends AbstractRemoteRepository<string, UserImage> {

    constructor(http: HttpClient,
        authService: AuthService) {

        super(
            (item: UserImage) => item.Id,
            http,
            authService,
            UserImage);
    }

    public retrieveHttpCall(id: string): Observable<UserImage> {
        return this.http.get<UserImage>(`${environment.apiUrl}/user/profile/images/options/${id}`, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    public retrieveBulkHttpCall(ids: string[]): Observable<UserImage[]> {
        return this.http.get<UserImage[]>(`${environment.apiUrl}/user/profile/images/options/`, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }
}