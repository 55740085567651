import { HttpClient } from '@angular/common/http';
import { AbstractService } from '../../abstractservice';
import { AuthService } from '../../auth.service';
import { Hydratable, JsonMappable } from '../../jsonmapper.service';

export class CacheRepository<TIdentifier, TCacheable extends (JsonMappable<TCacheable> | Hydratable<TCacheable>)> extends AbstractService {

    protected cache: Map<TIdentifier, TCacheable>;
    private typeInstance: TCacheable;

    constructor(
        protected identiferPropertySelector: (item: TCacheable) => TIdentifier,
        protected http: HttpClient,
        auth: AuthService,
        private type: new () => TCacheable) {

        super(auth);
        this.typeInstance = new type();
    }

    public cacheItems(...items: TCacheable[]): TCacheable[] {
        if (!items || !items.length) return null;
        this.initCache();
        items.forEach(item => this.cache.set(this.identiferPropertySelector(item), item));
        return items;
    }

    public uncacheItems(...items: TCacheable[]) {
        if (!items || !items.length || !this.cache) return;
        items.forEach(item => this.cache.delete(this.identiferPropertySelector(item)));
    }

    public getFromCache(...ids: TIdentifier[]): CacheResult<TIdentifier, TCacheable> {
        if (!this.cache) {
            return { retrieved: [], failed: ids };
        }

        var splitByCache = ids
            .map(id => ({ id: id, item: this.cache.get(id) }))
            .reduce<{ cached: TCacheable[], missed: TIdentifier[] }>(
                (accumulator, cachePair) => {
                    (cachePair.item ? accumulator.cached : accumulator.missed).push(<any>(cachePair.item ?? cachePair.id));
                    return accumulator;
                },
                { cached: [], missed: [] });
        return { retrieved: splitByCache.cached, failed: splitByCache.missed };
    }

    public getId(jsonShape: any) {
        return this.identiferPropertySelector(jsonShape);
    }

    private initCache() {
        if (this.cache) return;
        this.cache = new Map();
    }
}

export interface CacheResult<TIdentifier, TCacheable> {
    retrieved: TCacheable[];
    failed: TIdentifier[];
}

