import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { SignalType } from 'angular-to-phaser';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Channel, Friendship, NotificationSignal, RandomEventNotification, Signal, User } from '../dto';
import { AbstractService } from './abstractservice';
import { AuthService } from './auth.service';
import { JsonEnrichmentService } from './jsonmapper.service';
@Injectable({
    providedIn: 'root'
})
export class SignalService extends AbstractService {

    public latestNotificationSignal: BehaviorSubject<Signal> = new BehaviorSubject<Signal>(null);
    public toasts: BehaviorSubject<ToastNotification[]> = new BehaviorSubject<ToastNotification[]>([]);

    private announcements: BehaviorSubject<AnnouncementNotification[]> = new BehaviorSubject<AnnouncementNotification[]>([]);

    private connection: signalR.HubConnection;

    constructor(authService: AuthService, private http: HttpClient, private jsonMapperService: JsonEnrichmentService) {
        super(authService);
    }

    public async initializeConnection(): Promise<void> {
        if (!this.connection) {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(environment.signalRBase + '/notificationhub', { accessTokenFactory: () => this.authService.jwtToken })
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: ctx => {
                        if (ctx.previousRetryCount < 5) { //this will retry 5 times over the course of 3.75sec
                            return ctx.previousRetryCount * 250;
                        }
                        if (ctx.previousRetryCount < 30) { //this will retry 25 times over 6 minutes
                            return 15000;
                        }
                        if (ctx.previousRetryCount > 300) {
                            return 3 * 60 * 1000; //3min
                        }
                        return 60 * 1000; //1 min
                    }
                })
                .build();
            this.connection.on('ReceiveNotification', this.receiveNotification.bind(this));
            this.connection.onclose(async () => {
                console.log('SignalR connection closed.');
            });

            this.startSignalR();
        }
    }

    private async startSignalR() {
        try {
            await this.connection.start();
            console.log('SignalR Connected.');
            this.connection.invoke('Connect');
        } catch (err) {
            console.log(err);
            setTimeout(() => this.startSignalR(), 1000);
        }
    };

    private async receiveNotification(serverNotification: Signal): Promise<void> {
        if (serverNotification) {
            switch (serverNotification.SignalType) {
                case SignalType.Notification: serverNotification = new NotificationSignal(<NotificationSignal>serverNotification, this.authService.authenticatedUser$.getValue().Username, this.jsonMapperService.jsonEnricher());
                    break;
                default: break;
            }
            this.latestNotificationSignal.next(serverNotification); //this observable triggers dispatch to the appropriate handlers
        }
    }

    public createToast(body: string, title: string, visibleDuration: number | null): void {
        this.addToast(new BasicToast(body, title, visibleDuration));
    }

    public addToast(toast: ToastNotification): void {
        const toastArray = this.toasts.getValue();
        toast.onClose = () => { toast.visible = false; this.removeToast(toast); };
        toastArray.push(toast);
        this.toasts.next(toastArray);
    }

    private removeToast(toast: ToastNotification) {
        const toastArray = this.toasts.getValue();
        const index = toastArray.findIndex(t => t === toast);
        if (index >= 0) {
            toastArray.splice(index, 1);
            this.toasts.next(toastArray);
        }
    }

    public addAnnouncement(announcement: AnnouncementNotification) {
        announcement.onClose = () => { this.removeAnnouncement(announcement); };

        const anns = this.announcements.getValue();
        anns.push(announcement);
        this.announcements.next(anns);
    }

    private removeAnnouncement(announcement: AnnouncementNotification) {
        const announcementArray = this.announcements.getValue();
        const index = announcementArray.findIndex(t => t === announcement);
        if (index >= 0) {
            announcementArray.splice(index, 1);
            this.announcements.next(announcementArray);
        }
    }

}


export abstract class UINotification {
    public title: string;
    public onClose: Function;
}

export class AnnouncementNotification extends UINotification {
    constructor(body: string, title: string, eventInformation: RandomEventNotification) {
        super();
        this.body = body;
        this.title = title;
        this.eventInformation = eventInformation;
    }
    public body: string;
    public eventInformation: RandomEventNotification;
}

export abstract class ToastNotification extends UINotification {
    public visibleDuration: number | null;
    public visible = true;
}

export class BasicToast extends ToastNotification {
    constructor(body: string, title: string, visibleDuration: number | null) {
        super();
        this.title = title;
        this.body = body;
        this.visibleDuration = visibleDuration;
    }
    public body: string;
}

export class AchievementToast extends ToastNotification {
    constructor(description: string, title: string, image: string, visibleDuration: number | null) {
        super();
        this.name = title;
        this.title = 'Achievement Unlocked!';
        this.body = description;
        this.image = image;
        this.visibleDuration = visibleDuration;
    }
    public body: string;
    public image: string;
    public name: string;
}

export class MessageToast extends ToastNotification {
    constructor(newMessage: string, senderUsername: string, channel: Channel, visibleDuration: number | null) {
        super();
        this.message = newMessage;
        this.senderUsername = senderUsername;
        this.channel = channel;
        this.visibleDuration = visibleDuration;

        this.title = `New message from ${channel.Name ? channel.Name : senderUsername}`;
    }
    public message: string;
    public senderUsername: string;
    public channel: Channel;
}

export class FriendshipToast extends ToastNotification {
    constructor(alertText: string, friendshipDetails: Friendship, visibleDuration: number | null, activeUser: User) {
        super();
        this.friendshipDetails = friendshipDetails;
        this.visibleDuration = visibleDuration;
        if (this.friendshipDetails.RecipientUsername.localeCompare(activeUser.Username) === 0) {

            if (this.friendshipDetails.Accepted) {
                this.visible = false;
                this.visibleDuration = 0; return;
            }
            this.message = `${this.friendshipDetails.SenderUsername} asked to be your friend!`;

        } else if (this.friendshipDetails.SenderUsername.localeCompare(activeUser.Username) === 0) {
            if (this.friendshipDetails.Accepted) {
                this.message = `${this.friendshipDetails.RecipientUsername} accepted your friend request!`;
            } else {
                this.visible = false;
                this.visibleDuration = 0; return;
            }
        }
        this.title = alertText;
    }
    public friendshipDetails: Friendship;
    public message: string;
    public channel: Channel;
}
