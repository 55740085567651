import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhaserHttpMethod } from 'angular-to-phaser';
import { Observable, catchError, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ItemTypeConfig } from '../../../dto';
import { AuthService } from '../../auth.service';
import { AbstractRemoteRepository } from './abstract-remote-repository';

@Injectable({
    providedIn: 'root'
})
export class ItemTypeRepositoryService extends AbstractRemoteRepository<number, ItemTypeConfig> {

    constructor(http: HttpClient,
        authService: AuthService) {

        super(
            (item: ItemTypeConfig) => item.Id,
            http,
            authService,
            ItemTypeConfig);
    }

    public retrieveHttpCall(id: number): Observable<ItemTypeConfig> {
        return this.http.get<ItemTypeConfig>(`${environment.apiUrl}/inventory/type/${id}`, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    public retrieveBulkHttpCall(ids: number[]): Observable<ItemTypeConfig[]> {
        return this.http.request<ItemTypeConfig[]>(PhaserHttpMethod.GET.toString(), `${environment.apiUrl}/inventory/type/`,
            { body: ids, ...this.httpOptionsAuthJson() })
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }
}
