import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, catchError, throwError } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { UIStateService } from '../../services/ui-state.service';
import { View } from '../base.component';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent extends View implements OnInit {

    constructor(private activeModal: NgbActiveModal, authService: AuthService, uiStateService: UIStateService) { super(authService, uiStateService); }
    errorState = false;

    ngOnInit(): void {
    }

    submitSignIn(loginForm: NgForm): void {

        this.subscriptions.push(this.authService.loginWithCredentials(loginForm.value.emailAddress, loginForm.value.password).pipe(catchError((error: HttpErrorResponse) => {
            if (error.status < 500 && error.status > 399) {
                this.errorState = true;
                return EMPTY;
            } else {
                return throwError(() => error.error);
            }
        })).subscribe(
            (successfulAuthResponse) => {
                if (successfulAuthResponse) { this.closeModal(); }
            },
        ));
    }

    closeModal(): void {
        this.activeModal.close();
    }

}
