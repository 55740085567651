import { HttpClient } from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ShadowPopoverDirective } from '../directives/shadow-popover.directive';
import { Interaction, InteractionReply } from '../dto';
import { AbstractService } from './abstractservice';
import { AuthService } from './auth.service';
import { JsonEnrichmentService } from './jsonmapper.service';

@Injectable({
    providedIn: 'root'
})
export class TutorialService extends AbstractService {

    private SetupWizardNpcId = "setupWizard";
    public kitTaken = false;
    public tutorialComplete = false;
    public alreadyTutorialPrompted = false;
    public tutorialPostponed = false;
    public tutorialIsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private static INITIAL_HELLO_NPC_ACTION_CODE = 4;
    private static STARTER_KIT_ACTION_CODE = 3;
    private static POSTPONE_TUTORIAL_ACTION_CODE = 1;

    private tutorialComponentType: Component = null;

    tutorialOptions: InteractionReply[];
    appShellTutorialSections: ShadowPopoverDirective[] = [];
    tutorialSections: ShadowPopoverDirective[] = [];
    wizardMessage: Interaction;
    currentTutorialStep = 0;

    constructor(private http: HttpClient, private modalService: NgbModal, authService: AuthService, private jsonMapper: JsonEnrichmentService) {
        super(authService);
    }

    public resetTutorialState() {
        this.currentTutorialStep = 0;
        this.determineTutorialCompleteAndSetWizardState().subscribe();
    }

    public showTutorial() {
        if ((!this.authService.sessionSummary?.TutorialComplete && !this.tutorialComplete) || !this.kitTaken) {
            this.determineTutorialCompleteAndSetWizardState().subscribe(noTutorialRemaining => {
                if (!noTutorialRemaining) {
                    this.modalService.open(this.tutorialComponentType);
                }
            });
        }
    }

    public checkShowTutorial() {
        if (!this.authService.sessionSummary?.TutorialComplete && this.tutorialIsLoaded.value && this.authService.isAuthenticated() && !this.alreadyTutorialPrompted && !this.tutorialComplete) {
            this.alreadyTutorialPrompted = true;
            this.showTutorial();
        }
    }

    public addTutorialComponent(tutorialComponentType: any) {
        this.tutorialComponentType = tutorialComponentType;
        this.tutorialIsLoaded.next(this.tutorialComponentType !== null && this.tutorialSections.find(appShellElement => appShellElement.appShadowPopover === 'tutorial1') !== undefined);
        this.checkShowTutorial();
    }

    public addAppShellTutorialSection(pops: ShadowPopoverDirective[]) {
        this.appShellTutorialSections.push(...pops);
        this.tutorialIsLoaded.next(this.tutorialComponentType !== null && this.tutorialSections.find(appShellElement => appShellElement.appShadowPopover === 'tutorial1') !== undefined);
        this.checkShowTutorial();
    }

    public addTutorialSections(pops: ShadowPopoverDirective[]) {
        this.tutorialSections.push(...pops);
        this.tutorialIsLoaded.next(this.tutorialComponentType !== null && this.tutorialSections.find(appShellElement => appShellElement.appShadowPopover === 'tutorial1') !== undefined);
        this.checkShowTutorial();
    }
    public removeTutorialSection(section: ShadowPopoverDirective) {
        this.appShellTutorialSections = this.appShellTutorialSections.filter(s => s !== section);
        this.tutorialSections = this.tutorialSections.filter(s => s !== section);
    }

    public determineTutorialCompleteAndSetWizardState(): Observable<boolean> {
        if (this.authService.sessionSummary?.TutorialComplete) {
            this.kitTaken = true;
            this.tutorialComplete = true;
            return of(this.kitTaken && this.tutorialComplete);
        }
        return this.http.post<Interaction>(environment.apiUrl + `/world/npc/${this.SetupWizardNpcId}/interact/hello`, null, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(AbstractService.handleError),
                switchMap(interaction => {
                    if (interaction) {
                        this.wizardMessage = interaction;
                    }
                    this.tutorialOptions = interaction.Responses;
                    if (interaction.Responses?.length === 0 ||
                        (interaction?.Responses.length === 1 &&
                            (!interaction.Responses[0].Actions?.find(a => a.DirectAction) ||
                                interaction.Responses[0].Actions?.find(a => a.DirectAction.ActionCode === TutorialService.STARTER_KIT_ACTION_CODE)))) {

                        let actionWithCode = interaction.Responses[0]?.Actions?.find(a => a.DirectAction && a.DirectAction.ActionCode === TutorialService.STARTER_KIT_ACTION_CODE);

                        this.kitTaken = (interaction.Responses?.length === 0) || !actionWithCode;
                        this.tutorialComplete = true;
                        this.tutorialPostponed = false;
                    } else {
                        this.tutorialComplete = false;
                        this.kitTaken = false;
                        this.processInteraction(interaction);
                    }

                    return of(this.kitTaken && this.tutorialComplete);
                }));
    }

    public submitTutorialChoice(option: number): Observable<Interaction> {
        return this.http.post<Interaction>(environment.apiUrl + `/world/npc/${this.SetupWizardNpcId}/interact/${option}`, null, this.httpOptionsAuthJson())
            .pipe(
                map(interaction => new Interaction(interaction, this.jsonMapper.jsonEnricher())),
                tap(interaction => {
                    this.processInteraction(interaction);
                    if (option === TutorialService.POSTPONE_TUTORIAL_ACTION_CODE) {
                        this.tutorialPostponed = true;
                    }
                    this.currentTutorialStep = interaction.ContextSpecificData.CurrentStep;
                }),
                retry(2),
                catchError(AbstractService.handleError));
    }

    public advanceToSubsequentAction() {
        this.wizardMessage = this.wizardMessage.Responses.find(r => r.Actions.find(a => a.NextEvent)).Actions.find(a => a.NextEvent).NextEvent;
        this.tutorialOptions = this.wizardMessage.Responses;
    }

    private processInteraction(interaction: Interaction) {
        if (interaction.Responses?.length > 0) {
            this.tutorialOptions = interaction.Responses;
        }
        else if (interaction?.Responses?.length > 0) {
            this.tutorialOptions = interaction.Responses;
        }
        this.currentTutorialStep = interaction.ContextSpecificData.CurrentStep;
        this.wizardMessage = interaction ?? this.wizardMessage;

    }
}
