<div class="modal-header">
    <h2 class="modal-title">{{ errorTitle }}</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
    @if (imagePath) {
        <div class="row">
            <div class="col-sm-12 text-center">
                @if (imagePath) {
                    <img class="ui-img" [src]="imagePath" />
                }
            </div>
        </div>
    }
    <div class="row">
        <div class="col-sm-12">
            <p>{{ errorText }}</p>
            @if (linkRouterPath && linkText) {
                <p>
                    <a [routerLink]="[linkRouterPath]" (click)="closeModal()">{{ linkText }}</a>
                </p>
            }
        </div>
    </div>
</div>
<div class="modal-footer">
    @if (!linkRouterPath && !linkText) {
        <p><button class="btn btn-primary" (click)="closeModal()">Okay</button></p>
    }
</div>
