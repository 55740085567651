import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ChangeDetectorRef, Directive, ElementRef, Inject, Injector, Input, NgZone, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbRTL } from '@ng-bootstrap/ng-bootstrap/util/rtl';

@Directive({
    selector: '[appShadowPopover]',
    exportAs: 'appShadowPopover'
})
export class ShadowPopoverDirective extends NgbPopover {

    @Input() appShadowPopover: string | TemplateRef<any>;
    public domElement: HTMLElement;

    constructor(_elementRef: ElementRef<HTMLElement>, _renderer: Renderer2, injector: Injector,
        viewContainerRef: ViewContainerRef, config: NgbPopoverConfig, _ngZone: NgZone,
        @Inject(DOCUMENT) _document: any, _changeDetector: ChangeDetectorRef,
        applicationRef: ApplicationRef) {
        super();
        // super(_elementRef, _renderer, injector, viewContainerRef, config, _ngZone, _document, _changeDetector, applicationRef);
        this.domElement = _elementRef.nativeElement;
        this.triggers = "manual";
    }

    public override open(context?: any): void {
        this.ngbPopover = this.appShadowPopover;
        if (!this.isOpen()) {
            const div = document.createElement('div');
            div.classList.add('modal-backdrop'); div.classList.add('fade');
            document.body.appendChild(div);
            setTimeout(() => div.classList.add('show'), 50);

        }
        super.open(context);
    }

    public override close(): void {
        if (this.isOpen()) {
            const divs = (<Element[]>[].slice.call(document.getElementsByClassName('modal-backdrop')));
            divs.forEach(div => div.classList.remove('show'));

            setTimeout(() => divs.forEach(div => div.remove()), 500);
        }
        super.close();
    }
}
