import { animate, style, transition, trigger } from '@angular/animations';
import { ComponentRef, Injectable, Type } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { AbstractService } from './abstractservice';
import { AuthService } from './auth.service';
declare let bootstrap: any;

@Injectable({
    providedIn: 'root'
})
export class UIStateService extends AbstractService {

    public static ITEM_LEAVE_ANIMATION_DESKTOP = trigger('itemRemovalDesktop', [
        transition(':enter', [
            style({ opacity: 0 }), animate('750ms', style({ opacity: 1 }))]
        ),
        transition(':leave',
            [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))]
        )
    ]);

    public static ITEM_LEAVE_ANIMATION_MOBILE = trigger('itemRemovalMobile', [
        transition(':enter', [
            style({ opacity: 0 }), animate('700ms', style({ opacity: 1 }))]
        ),
        transition(':leave',
            [style({ opacity: 1, height: '*' }), animate('200ms', style({ opacity: 0, height: '0px' }))]
        )
    ]);



    public hideApplicationShellElements = new BehaviorSubject<boolean>(false);

    public isMobile: boolean = true;

    hideableDropdowns: Map<string, any[]> = new Map();
    hideableCollapsers: Map<string, any[]> = new Map();
    globalToggles: (() => void)[] = [];
    togglers: Map<string, any[]> = new Map();

    public activeTaskEventEmitter: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(authService: AuthService, private modalService: NgbModal) {
        super(authService);
    }

    public hideAppShell() {
        this.hideApplicationShellElements.next(true);
        this.hideAll();
    }


    public showAppShell() {
        this.hideApplicationShellElements.next(false);
    }

    /**
       * Global Autohide Drowndowns are primarily intended for search results and the user details dropdown
       * These need to be automatically dismissable because they stop being relevant when any nav event happens.
       * Use this to autohide any dropdown on nav.
       */
    public addGlobalAutohideDropdown(domElement: { name: string; element: Element }): void {
        if (this.hideableDropdowns.get(domElement.name)) {
            const elems = this.hideableDropdowns.get(domElement.name);
            elems.push(new bootstrap.Dropdown(domElement.element));
            this.hideableDropdowns.set(domElement.name, elems);
        } else {
            this.hideableDropdowns.set(domElement.name, [new bootstrap.Dropdown(domElement.element)]);
        }
    }

    /**
     * Global Autohide Drowndowns are primarily intended for search results and the user details dropdown
     * These need to be automatically dismissable because they stop being relevant when any nav event happens.
     * Use this to autohide any dropdowns on nav.
     */
    public addGlobalAutohideDropdowns(domElements: { name: string; element: Element }[]): void {
        domElements.forEach(domElement => {
            if (this.hideableDropdowns.get(domElement.name)) {
                const elems = this.hideableDropdowns.get(domElement.name);
                elems.push(new bootstrap.Dropdown(domElement.element));
                this.hideableDropdowns.set(domElement.name, elems);
            } else {
                this.hideableDropdowns.set(domElement.name, [new bootstrap.Dropdown(domElement.element)]);
            }
        });
    }

    /**
     * Global Autohide Collapsers are primarily intended for the two mobile sidebars -
     * the primary nav sidebar and the social pane
     * These need to be automatically collapsible on mobile because the screen is too small
     * to leave either open while navigating around the site, so any nav action should collapse them
     * gracefully.
     * Use this method when the screen gets small and you need to autohide your component.
     */
    public addGlobalAutohideCollapser(domElement: { name: string; element: Element }): void {
        if (this.hideableCollapsers.get(domElement.name)) {
            const elems = this.hideableCollapsers.get(domElement.name);
            elems.push(UIStateService.GetOrCreateCollapser(domElement.element));
            this.hideableCollapsers.set(domElement.name, elems);
        } else {
            this.hideableCollapsers.set(domElement.name, [UIStateService.GetOrCreateCollapser(domElement.element)]);
        }

    }

    /**
     * Global Autohide Collapsers are primarily intended for the two mobile sidebars -
     * the primary nav sidebar and the social pane
     * These need to be automatically collapsible on mobile because the screen is too small
     * to leave either open while navigating around the site, so any nav action should collapse them
     * gracefully.
     * Use this method when the screen gets small and you need to autohide your components.
     */
    public addGlobalAutohideCollapsers(domElements: { name: string; element: Element }[]): void {
        domElements.forEach(domElement => {
            if (this.hideableCollapsers.get(domElement.name)) {
                const elems = this.hideableCollapsers.get(domElement.name);
                elems.push(UIStateService.GetOrCreateCollapser(domElement.element));
                this.hideableCollapsers.set(domElement.name, elems);
            } else {
                this.hideableCollapsers.set(domElement.name, [UIStateService.GetOrCreateCollapser(domElement.element)]);
            }
        });
    }

    /**
     * Global Autohide Collapsers are primarily intended for the two mobile sidebars -
     * the primary nav sidebar and the social pane
     * These need to be automatically collapsible on mobile because the screen is too small
     * to leave either open while navigating around the site, so any nav action should collapse them
     * gracefully.
     * Use this method when the screen gets big and you no longer need to autohide your component.
     */
    public removeGlobalAutohideCollapser(name: string) {
        this.hideableCollapsers.delete(name);
    }

    public hideAll() {
        this.hideableDropdowns.forEach(group => group.forEach(dd => dd.hide()));
        this.hideableCollapsers.forEach(group => group.forEach(c => c.hide()));
        this.globalToggles.forEach(fn => fn());
    }

    public hide(id: string) {
        this.hideableDropdowns.get(id)?.forEach(dd => dd.hide());
        this.hideableCollapsers.get(id)?.forEach(c => c.hide());

    }

    public show(id: string) {
        this.hideableDropdowns.get(id)?.forEach(dd => dd.show());
        this.hideableCollapsers.get(id)?.forEach(c => c.show());
    }

    public toggle(id: string) {
        this.togglers.get(id).forEach(t => t.toggle());
    }

    public addToggler(id: string, element: any) {
        if (this.togglers.get(id)) {
            const elems = this.togglers.get(id);
            elems.push(element);
            this.togglers.set(id, elems);
        } else {
            this.togglers.set(id, [element]);
        }
    }

    public removeToggler(id: string) {
        this.togglers.delete(id);
    }

    public addGlobalToggle(fn: () => void) {
        this.globalToggles.push(fn);
    }

    public openModalWithComponent(modalOpts: NgbModalOptions,
        breadcrumb: string,
        modalTitle: string,
        componentType: Type<any>,
        parentComponentType: Type<any>,
        initializer: (c: any /** MUST BE A COMPONENT FROM COMPONENTREF.INSTANCE */, openedModal: NgbActiveModal, parentModal: any) => void,
        onClose: () => void = () => { }): NgbActiveModal {


        let openedModal = this.configureModal(modalOpts, breadcrumb, parentComponentType, modalTitle, onClose);
        (<any>openedModal.componentInstance).openNestedComponentInModal(componentType, initializer);
        return openedModal;
    }

    public openModalWithRoute(
        modalOpts: NgbModalOptions,
        route: string,
        breadcrumb: string,
        modalTitle: string,
        parentComponentType: Type<any>,
        initializer: (c: ComponentRef<any>, openedModal: NgbActiveModal, parentModal: any) => void,
        onClose: () => void = null) {

        let openedModal = this.configureModal(modalOpts, breadcrumb, parentComponentType, modalTitle, onClose);
        (<any>openedModal.componentInstance).navigateToRouteForModal(route, breadcrumb, modalTitle ?? modalTitle, initializer);
    }

    private configureModal(
        modalOpts: NgbModalOptions,
        breadcrumb: string,
        parentType: Type<any>,
        modalTitle: string,
        onClose: () => void = () => { }) {
        let openedModal = this.modalService.open(parentType, modalOpts);
        openedModal.result.then(onClose, () => { });
        (<any /*IGenericModal*/>openedModal.componentInstance).setModalData(openedModal, breadcrumb, modalTitle);
        return openedModal;
    }

    public static GetOrCreateCollapser(element: Element): any {
        return bootstrap.Collapse.getOrCreateInstance(element, { toggle: false });
    }


}
