<div class="row">
    @for (newsItem of news | async; track newsItem) {
        <div class="col-md-4 mb-3">
            <div [id]="newsItem.AlertScrollTargetId" class="card card-hover">
                <img [src]="'assets/images/tiles/' + newsItem.ImagePath" class="card-img-top" alt="" />
                <div class="card-body">
                    <h3>{{ newsItem.Title }}</h3>
                    @if (newsItem.MessageHtml) {
                        <span [innerHtml]="newsItem.MessageHtml"></span>
                    }
                    <p>
                        {{ newsItem.Message }}
                        @if (newsItem.RouterLink) {
                            <a class="stretched-link" [routerLink]="[newsItem.RouterLink]"></a>
                        }
                        @if (newsItem.ContentFilePath) {
                            <a class="stretched-link" [routerLink]="['/static/' + newsItem.Id]"></a>
                        }
                    </p>
                </div>
            </div>
        </div>
    }
</div>
