import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';
import { Friendship, ItemTypeConfig, User, UserImage, UserProfile } from 'src/app/dto';
import { AbstractService } from '../abstractservice';
import { AuthService } from '../auth.service';
import { Cacheable, Hydratable, JsonEnricher, JsonMappable } from '../jsonmapper.service';
import { CacheRepository, CacheResult } from './cache/cache-repository';
import { AbstractRemoteRepository } from './remote/abstract-remote-repository';
import { FriendshipRepositoryService } from './remote/friendship-repository.service';
import { ItemTypeRepositoryService } from './remote/item-type-repository.service';
import { UserImageRepositoryService } from './remote/user-image-repository.service';
import { UserProfileRepositoryService } from './remote/user-profile-repository.service';
import { UserRepositoryService } from './remote/user-repository.service';

@Injectable({
    providedIn: 'root'
})
export class UnifiedRepositoryGatewayService extends AbstractService {

    public jsonMapper: JsonEnricher;

    private userCache: CacheRepository<string, User>;
    private itemTypeCache: CacheRepository<number, ItemTypeConfig>;
    private userProfileCache: CacheRepository<string, UserProfile>;
    private userImageCache: CacheRepository<string, UserImage>;


    constructor(auth: AuthService,
        private http: HttpClient,
        public userRepository: UserRepositoryService,
        public itemTypeRepository: ItemTypeRepositoryService,
        public userProfileRepository: UserProfileRepositoryService,
        public friendshipRepository: FriendshipRepositoryService,
        public userImageRepository: UserImageRepositoryService) {
        super(auth);
        this.jsonMapper = new JsonEnricher(this);

        this.userCache = new CacheRepository<string, User>(user => user.Username, http, auth, User);
        this.itemTypeCache = new CacheRepository<number, ItemTypeConfig>(itemType => itemType.Id, http, auth, ItemTypeConfig);
        this.userProfileCache = new CacheRepository<string, UserProfile>(userProfile => userProfile.Username, http, auth, UserProfile);
        this.userImageCache = new CacheRepository<string, UserImage>(userImage => userImage.Id, http, auth, UserImage);
    }

    /** inadvisable! */
    public retrieveWithNoId<TCacheable>(withFullHydration: boolean, withFullMapping: boolean, type: new () => TCacheable): Observable<TCacheable[]> {
        var remoteRepo: AbstractRemoteRepository<any, any> = this.getRemoteRepository(type.name);
        var pipeFn = withFullHydration && this.isHydratableType(type) ?
            switchMap((items: TCacheable[]) => this.hydrateItems(type, ...items)) :
            (withFullMapping && this.isJsonMappableType(type) ?
                map((items: TCacheable[]) => this.mapItems(type, ...items)) :
                map((item: TCacheable[]) => item));

        return remoteRepo.retrieveBulkHttpCall(null)
            .pipe(pipeFn);

    }

    public retrieve<TEntity>(withFullHydration: boolean, withFullMapping: boolean, type: new () => TEntity, ...ids: any[]): Observable<TEntity[]> {
        if (ids === null || ids === undefined || ids.length === 0) return of([]);
        var remoteRepo: AbstractRemoteRepository<any, any> = this.getRemoteRepository(type.name);

        ids = ids.filter((item, index, arr) => arr.indexOf(item) === index); //dedupe

        if (ids.length === 1) {
            return this.retrieveSingle(withFullHydration, withFullMapping, type, ids[0]).pipe(map(item => [item]));
        } else {
            var pipeFn = withFullHydration && this.isHydratableType(type) ?
                switchMap((items: TEntity[]) => this.hydrateItems(type, ...items)) :
                (withFullMapping && this.isJsonMappableType(type) ?
                    map((items: TEntity[]) => this.mapItems(type, ...items)) :
                    map((items: TEntity[]) => items));

            return remoteRepo.retrieveBulkHttpCall(ids)
                .pipe(
                    pipeFn,
                    tap(items => this.cacheItems(type, ...items)));
        }
    }

    public retrieveSingle<TEntity>(withFullHydration: boolean, withFullMapping: boolean, type: new () => TEntity, id: any): Observable<TEntity> {
        if (id === null || id === undefined) return EMPTY;
        var remoteRepo: AbstractRemoteRepository<any, any> = this.getRemoteRepository(type.name);


        var pipeFn = withFullHydration && this.isHydratableType(type) ?
            switchMap((item: TEntity) => this.hydrateItems(type, item).pipe(map(items => items[0]))) :
            (withFullMapping && this.isJsonMappableType(type) ?
                map((item: TEntity) => this.mapItems(type, item)[0]) :
                map((item: TEntity) => item));


        return remoteRepo.retrieveHttpCall(id)
            .pipe(
                pipeFn,
                tap(item => this.cacheItems(type, item)),
            );
    }

    public getOrRetrieve<TEntity>(withFullHydration: boolean, withFullMapping: boolean, type: new () => TEntity, ...ids: any[]): Observable<TEntity[]> {
        if (ids === null || ids === undefined || ids.length === 0) return of([]);

        ids = ids.filter((item, index, arr) => arr.indexOf(item) === index); //dedupe

        var cacheResult = this.getFromCache(type, ...ids);
        if (!cacheResult || cacheResult.failed?.length > 0) {
            return this.retrieve(withFullHydration, withFullMapping, type, ...cacheResult.failed)
                .pipe(
                    map(items => items.concat(cacheResult.retrieved))
                );
        } else {
            return of(cacheResult.retrieved);
        }
    }

    public cacheItems<TEntity>(type: new () => TEntity, ...items: TEntity[]): TEntity[] {
        if (!items || !items.length) return null;
        if (this.isCacheableType(type)) {
            let repo = this.getCacheRepository(type.name);
            repo?.cacheItems(...<Cacheable<any>[]>items);

            items.forEach(item => {
                (<JsonMappable<any>>item).cacheNestedElements(this);
            });
        }
        return items;
    }

    public uncacheItems<TEntity>(type: new () => TEntity, ...items: TEntity[]): TEntity[] {
        if (!items || !items.length) return null;
        if (this.isCacheableType(type)) {
            let repo = this.getCacheRepository(type.name);
            repo.uncacheItems(...<Cacheable<any>[]>items);
        }
        return items;
    }

    protected mapItems<TEntity>(type: new () => TEntity, ...items: TEntity[]): TEntity[] {
        if (!this.isJsonMappableType(type)) return items;
        return (<JsonMappable<TEntity>[]>items).map((item: JsonMappable<TEntity>) => {
            if (item.isJsonMappableInstance) {
                return <TEntity>item;
            } else {
                return <TEntity>this.jsonMapper.map(item, <any>type);
            }
        });
    }

    protected hydrateItems<TEntity>(type: new () => TEntity, ...items: TEntity[]): Observable<TEntity[]> {
        if (!this.isHydratableType(type)) {
            return of(items);
        }

        var splitByHydration = items.reduce<{ hydrated: TEntity[], dehydrated: TEntity[] }>(
            (accumulator, item) => {
                ((<Hydratable<any>>item).hasBeenRehydrated ? accumulator.hydrated : accumulator.dehydrated).push(item);
                return accumulator;
            },
            { hydrated: [], dehydrated: [] });

        if (splitByHydration.dehydrated.length > 0) {
            return forkJoin(
                splitByHydration.dehydrated.map(item => (<Hydratable<any>>new type()).hydrate(item, this.jsonMapper))
            ).pipe(
                map(items => items.concat(splitByHydration.hydrated))
            );
        }
        return of(items);
    }

    public getFromCache<TIdentifier, TCacheable>(type: new () => TCacheable, ...ids: TIdentifier[]): CacheResult<TIdentifier, TCacheable> {
        let cache = this.getCacheRepository(type.name);
        if (!this.isCacheableType(type) || !cache) {
            return { retrieved: [], failed: ids };
        }

        return <CacheResult<TIdentifier, TCacheable>>cache.getFromCache(...ids);
    }

    public determineId<TIdentifier, TCacheable>(type: new () => TCacheable, jsonShape: any): CacheResult<TIdentifier, TCacheable> {
        let repo = this.getCacheRepository(type.name) ?? this.getRemoteRepository(type.name);
        return repo?.getId(jsonShape);
    }

    private getRemoteRepository<T extends Cacheable<T>>(stringType: string): AbstractRemoteRepository<any, T> {
        //the <AbstractRemoteRepository<any, T>> <any> casting is because of the weirdness of generics + TS. I want compiler hints farther up the stack
        //which means giving this a reasonable return type instead of any any any any, but those any's gotta go somewhere, so here they are
        switch (stringType) {
            case ItemTypeConfig.name: return this.itemTypeRepository as unknown as AbstractRemoteRepository<any, T>;
            case User.name: return this.userRepository as unknown as AbstractRemoteRepository<any, T>;
            case UserProfile.name: return this.userProfileRepository as unknown as AbstractRemoteRepository<any, T>;
            case Friendship.name: return this.friendshipRepository as unknown as AbstractRemoteRepository<any, T>;
            case UserImage.name: return this.userImageRepository as unknown as AbstractRemoteRepository<any, T>;
            default: console.error(`Invalid type ${stringType} not supported by URG`); return null;
        }
    }

    private getCacheRepository<T extends Cacheable<T>>(stringType: string): CacheRepository<any, T> {
        //the <CacheRepository<any, T>> <any> casting is because of the weirdness of generics + TS. I want compiler hints farther up the stack
        //which means giving this a reasonable return type instead of any any any any, but those any's gotta go somewhere, so here they are
        switch (stringType) {
            case ItemTypeConfig.name: return <CacheRepository<any, T>><any>this.itemTypeCache;
            case User.name: return <CacheRepository<any, T>><any>this.userCache;
            case UserProfile.name: return <CacheRepository<any, T>><any>this.userProfileCache;
            case UserImage.name: return <CacheRepository<any, T>><any>this.userImageCache;
            default: return null;
        }
    }

    private typeInstance(type: new () => any) {
        try {
            return this.getRemoteRepository(type.name).typeInstance;
        } catch { }
        return null;
    }

    private isCacheableType(type: new () => any) {
        return this.isHydratableType(type) || this.isJsonMappableType(type);
    }
    private isHydratableType(type: new () => any) {
        return (<Hydratable<any>>this.typeInstance(type))?.isHydratableInstance;

    }
    private isJsonMappableType(type: new () => any) {
        return this.typeInstance(type)?.isJsonMappableInstance;
    }
}
