@if (this.dismissed === false) {
    <div class="row mb-2" [ngClass]="alert.AlertCssClasses">
        <div class="col-12">
            <a class="btn btn-plain text-white" (click)="scrollTo(alert.AlertScrollTargetId)">
                @if (alert.AlertIconCssClasses) {
                    <i [class]="alert.AlertIconCssClasses"></i>
                }
                <b>{{ alert.AlertTitle }}</b> {{ alert.AlertMessage }}</a
            >
            @if (alert.AlertDismissable) {
                <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
            }
        </div>
    </div>
}
