import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from 'src/app/components/ui/error-modal/error-modal.component';
import { UserArgumentBag } from '../dto';
import { AuthService } from './auth.service';
import { JsonEnrichmentService } from './jsonmapper.service';
import { UnifiedRepositoryGatewayService } from './repository/unified-repository-gateway.service';
import { SignalService } from './signal.service';
import { UIStateService } from './ui-state.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {

    constructor(
        public authService: AuthService,
        public userService: UserService,
        public uiStateService: UIStateService,
        public notificationService: SignalService,
        public modalService: NgbModal,
        private router: Router,
        public jsonMapper: JsonEnrichmentService,
        public unifiedRepo: UnifiedRepositoryGatewayService
    ) { }

    public displayError(errorText: string, errorTitle: string, imagePath: string, errorLink: string, errorNavPrompt: string): NgbModalRef {
        const modalRef = this.modalService.open(ErrorModalComponent, { centered: true });
        const component = <ErrorModalComponent>modalRef.componentInstance;
        component.initialize(errorText, errorTitle, imagePath, errorLink, errorNavPrompt);
        return modalRef;
    }

    public UserArgumentBag: UserArgumentBag;
}
