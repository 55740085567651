import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, retry, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthenticationResponse, CreateAccountRequest, User, UserProfile } from './../dto';
import { AbstractService } from './abstractservice';
import { AuthService } from './auth.service';
import { UnifiedRepositoryGatewayService } from './repository/unified-repository-gateway.service';
import { SignalService } from './signal.service';


@Injectable({
    providedIn: 'root'
})
export class UserService extends AbstractService {

    public activeUser$: BehaviorSubject<User> = this.authService.authenticatedUser$;
    public activeUserProfile$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
    private userProfileFromActiveUser$: Observable<UserProfile>;

    constructor(private http: HttpClient, authService: AuthService, private notificationService: SignalService, private unifiedRepo: UnifiedRepositoryGatewayService) {
        super(authService);
        this.userProfileFromActiveUser$ = this.activeUser$.pipe(
            tap(user => console.log(`tapped ${user}`)),
            filter(user => !!user && !!user.Username && authService.isAuthenticated()),
            distinctUntilChanged((prevUser, currUser) => prevUser.Username === currUser.Username),
            switchMap(user => this.unifiedRepo.getOrRetrieve(true, true, UserProfile, user.Username)),
            map(profileArr => profileArr?.pop()),
        );
        this.userProfileFromActiveUser$.subscribe(userProfile => this.activeUserProfile$.next(userProfile));
    }

    public updateUserCash(delta: number) {
        if (!delta) return;
        const user = this.activeUser$.getValue();
        user.Cash += delta;
        this.activeUser$.next(user);
    }

    public checkUsername(name: string): Observable<void> {
        return this.http.get<void>(environment.apiUrl + `/user/valid/username/${name}`, this.httpOptionsAuthJson());
    }

    public checkEmail(email: string): Observable<void> {//<boolean> {
        return this.http.get<void>(environment.apiUrl + `/user/valid/email/${email}`, this.httpOptionsAuthJson());
    }

    public createUser(request: CreateAccountRequest): Observable<AuthenticationResponse> {
        return this.unifiedRepo.userRepository.createUser(request).pipe(
            tap(authR => {
                this.authService.sessionSummary = authR.SessionSummary;
                this.authService.loginResponse = authR;
                this.authService.jwtToken = authR.AuthToken;
                this.authService.authenticatedUser$.next(authR.SessionSummary.User);
            }),
            retry(2)
        );
    }

    public retrieveUserProfile(username: string): Observable<UserProfile> {
        return this.unifiedRepo.getOrRetrieve<UserProfile>(true, true, UserProfile, username).
            pipe(
                map(arr => arr[0]));
    }

    public updateUserProfile(userProfile: UserProfile): Observable<void> {
        return this.unifiedRepo.userProfileRepository.updateUserProfile(userProfile)
            .pipe(
                // map(arr => arr[0]),
                tap(() => this.activeUserProfile$.next(userProfile))
            );
    }

    public retrieveUser(username: string): Observable<User> {
        if (!username) {
            return this.unifiedRepo.retrieveSingle(true, true, User, username);
        } else {
            return this.unifiedRepo.getOrRetrieve(true, true, User, username).pipe(map(arr => arr[0]));
        }
    }

    public updateUser(user: User): Observable<void> {
        return this.unifiedRepo.userRepository.updateUser(user)
            .pipe(
                // map(arr => arr[0]),
                tap(() => this.activeUser$.next(user))
            );
    }

}
