import { HttpErrorResponse } from '@angular/common/http';
import { Component, Directive, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User } from '../dto';
import { AuthService } from '../services/auth.service';
import { UIStateService } from '../services/ui-state.service';
import { GenericModalComponent } from './ui/generic-modal/generic-modal.component';

@Directive()
export class View implements OnDestroy {

    public activeUser$: BehaviorSubject<User>;
    public activeUser: User;
    public isAuthenticated = false;
    protected subscriptions: Subscription[] = [];

    constructor(public authService: AuthService, protected uiStateService: UIStateService) {
        this.activeUser$ = authService.authenticatedUser$;
        this.subscribe(this.activeUser$, user => { this.activeUser = user; this.isAuthenticated = authService.isAuthenticated(); })
        this.uiStateService.showAppShell();
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(element => {
            if (!element.closed) { element.unsubscribe(); }
        });
    }

    public subscribe<T>(observable: Observable<T>, valueHandler: (value: T) => void, errorHandler: (error: HttpErrorResponse) => void = null) {
        this.subscriptions.push(observable.subscribe({
            next: valueHandler,
            error: errorHandler
        }));
    }

    public scrollTo(element: any): void {
        (document.getElementById(element) as HTMLElement).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
}

export class NestableAuthenticatedView extends View {
    protected isNested: boolean;
    constructor(protected router: Router, private currentRoute: ActivatedRoute, readonly componentRoute: string, authService: AuthService, redirectRoute: string, uiStateService: UIStateService) {
        super(authService, uiStateService);
        if (componentRoute && currentRoute) { //nest-only components don't have a route
            const path = this.currentRoute.snapshot.routeConfig.path;
            this.isNested = path.includes(componentRoute);
            if (!this.isNested) { //nesting in other components would change the current route, let the top level view component handle this
                setTimeout(() => {
                    if (!this.isAuthenticated) {
                        const link = [`/${redirectRoute}`];
                        this.router.navigate(link);
                    }
                }, 3000);
            }
        }
    }
}

@Component({
    template: ``,
})
export class BaseComponent extends View {
    constructor(public authService: AuthService, public uiStateService: UIStateService) {
        super(authService, uiStateService);
    }
}

@Component({
    template: `<p>Oh shit you're not supposed to see this</p>`,
    host: { class: 'container-fluid', style: "display:block" }
})
export class PageComponent extends BaseComponent {
    constructor(public authService: AuthService, public uiStateService: UIStateService) {
        super(authService, uiStateService);
    }
}

export class AuthenticatedPageComponent extends PageComponent {
    constructor(protected router: Router, authService: AuthService, redirectRoute: string, uiStateService: UIStateService) {
        super(authService, uiStateService);
        setTimeout(() => {
            if (!this.authService.isAuthenticated()) {
                this.router.navigate([`/${redirectRoute}`]);
            }
        }, 3000);
    }
}


@Component({
    template: `<p>Oh shit you're not supposed to see this</p>`,
    host: { class: 'container-fluid', style: "display:block" }
})
export class CompletableActionView extends PageComponent {

    constructor(authService: AuthService, uiStateService: UIStateService) {
        super(authService, uiStateService);
    }

    public completeAction(thisArg: any) {
        this.uiStateService.activeTaskEventEmitter.next(thisArg);
    }
}



export interface ITakeServerMetadata {
    acceptServerInput(title: string, description: string, injectedClass: string);
}

export interface IOverrideModalNavigation {
    initModalParentForNav(parentModal: GenericModalComponent): void;
}



