import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContentType } from 'angular-to-phaser';
import { BehaviorSubject, Observable, catchError, map, retry, takeWhile, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StaticContent, UserUpload } from '../dto';
import { AbstractService } from './abstractservice';
import { ApplicationService } from './application.service';

@Injectable({
    providedIn: 'root'
})
export class StaticContentService extends AbstractService {

    private static ALERT_DISMISS_LOCAL_KEY = 'DISMISSED_ALERT:';

    public allNews$: BehaviorSubject<StaticContent[]> = new BehaviorSubject<StaticContent[]>([]);
    public latestAlert$: Observable<StaticContent> = this.allNews$.pipe(map(n => n.find(n => n.AlertTitle || n.AlertMessage)));

    constructor(private coreAppService: ApplicationService, private httpClient: HttpClient) {
        super(coreAppService.authService);
        this.authService.authenticatedUser$
            .pipe(
                takeWhile(() => !coreAppService.authService.isAuthenticated(), true),
            )
            .subscribe(a => {
                if (a && coreAppService.authService.isAuthenticated()) {
                    this.getNewsPage(true, null).subscribe();
                }
            });
    }

    public getNextPage() {
        let news = this.allNews$.getValue();
        if (news.length) {
            this.getNewsPage(false, news[news.length].Id).subscribe();
        } else {
            this.getNewsPage(true, null).subscribe();
        }
    }

    public dismissAlert(alert: StaticContent) {
        window.localStorage.setItem(`${StaticContentService.ALERT_DISMISS_LOCAL_KEY}.Id:${alert.Id}`, Date.UTC.toString());
    }

    public alertDismissed(alert: StaticContent) {
        return window.localStorage.getItem(`${StaticContentService.ALERT_DISMISS_LOCAL_KEY}.Id:${alert.Id}`) != null;
    }


    public getUserUploads(source: string) {
        return this.httpClient.get<UserUpload[]>(`${environment.apiUrl}/staticContent/userUploads/${source}`, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }


    public getLatestUserUploads(source: string) {
        return this.httpClient.get<UserUpload>(`${environment.apiUrl}/staticContent/userUploads/latest/${source}`, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    public getStaticContentById(id: number) {
        return this.httpClient.get<StaticContent>(`${environment.apiUrl}/staticContent/${id}`, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    public getContent(path: string) {
        return this.httpClient.get<string>(`${environment.baseUrl}/${path}`, this.httpOptionsAuthHtml())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    /**
    * Retrieves a page of messages from the server and adds them to their viewable parent channel.
    * If fetchNewest is false, 100 messages will be fetched backwards in time from the oldest currently loaded message, otherwise only fetches
    * newer messages that have occurred after initial page load.
    */
    private getNewsPage(fetchNewest: boolean, lastId: number | null): Observable<StaticContent[]> {
        return this.httpClient.get<StaticContent[]>(environment.apiUrl + `/staticcontent/${ContentType.Announcement}/${lastId ? `${lastId}` : 'latest'}`,
            this.httpOptionsAuthJson())
            .pipe(
                map(statics => {
                    if (statics) {
                        statics = statics.map(channel => new StaticContent(channel));
                    } else {
                        statics = [];
                    }
                    return statics;
                }),
                tap(statics => this.allNews$.next(this.allNews$.getValue().concat(statics))),
                retry(2),
                catchError(this.handleError),
            );
    }


}
