import { HttpContext, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';


export abstract class AbstractService {


    constructor(protected authService: AuthService) {
    }

    public static httpOptionsAuthJson(jwtToken: string): HTTPOpts {
        var opts = {
            withCredentials: true,
            responseType: 'json',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + jwtToken
            })
        };
        return opts;
    }

    public static httpOptionsAuthHtml(jwtToken: string): HTTPOpts {
        var opts = {
            withCredentials: true,
            responseType: 'text',
            headers: new HttpHeaders({
                'Content-Type': 'text/html',
                Authorization: 'Bearer ' + jwtToken
            })
        };
        return opts;
    }

    public static handleError(error: HttpErrorResponse): Observable<never> {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error(`An unhandled client-side error occurred:, ${error.error.message}`);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `An unhandled server-side error occurred with code ${error.status}. ` +
                `Error was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => error);
    }

    protected httpOptionsAuthJson(): HTTPOpts {
        return AbstractService.httpOptionsAuthJson(this.authService.jwtToken);
    }

    protected httpOptionsAuthHtml(): HTTPOpts {
        return AbstractService.httpOptionsAuthHtml(this.authService.jwtToken);
    }

    protected handleError(error: HttpErrorResponse): Observable<never> {
        return AbstractService.handleError(error);
    }
}

export class HTTPOpts {
    headers?: HttpHeaders | { [header: string]: string | string[]; };
    context?: HttpContext;
    observe?: "body";
    reportProgress?: boolean;
    withCredentials?: boolean;
}
