import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UnifiedRepositoryGatewayService } from './repository/unified-repository-gateway.service';

@Injectable({
    providedIn: 'root'
})
export class JsonEnrichmentService {

    public jsonEnricher = () => this.unifiedRepoGateway?.jsonMapper
    public unifiedRepoGateway: UnifiedRepositoryGatewayService;
    constructor(repositoryService: UnifiedRepositoryGatewayService) {
        this.unifiedRepoGateway = repositoryService;
    }

    public retrieveItemWithId<T extends Cacheable<T>>(withFullHydration: boolean, withFullMapping: boolean, id: any, type: new () => T) {
        return this.unifiedRepoGateway.retrieve<T>(withFullHydration, withFullMapping, type, id);
    }

    /** 
     * Mapping refers to a light weight transform whereby JSON shaped like a T instance is mapped into a real T (enabling method access)
     * No serverside GETs 
    */
    public map<T extends JsonMappable<T>>(json: any, type: new () => T): T {
        return this.jsonEnricher().map(json, type);
    }
    /** Hydrating refers to taking a flyweight (or any flyweight subelement) and fully rehydrating it, including serverside fetches */
    public hydrate<T extends Hydratable<T>>(json: any, type: new () => T): Observable<T> {
        return this.jsonEnricher().hydrate(json, type);
    }

    public cache(item: any, type: new () => any) {
        return this.unifiedRepoGateway.cacheItems(type, item);
    }
}

export class JsonEnricher {

    public constructor(public repositoryService: UnifiedRepositoryGatewayService) { }

    /** Hydrating refers to taking a flyweight (or any flyweight subelement) and fully rehydrating it, including serverside fetches */
    public hydrate<T extends Hydratable<T>>(json: any, type: new () => T): Observable<T> {
        if (!json) return null;
        if ((<Hydratable<any>>json).hasBeenRehydrated) return json;

        return new type().hydrate(json, this);
    }

    /** 
     * Mapping refers to a light weight transform whereby JSON shaped like a T instance is mapped into a real T (enabling method access)
     * No serverside GETs 
    */
    public map<T extends JsonMappable<T>>(json: any, type: new () => T): T {
        if (!json) return null;
        if ((<JsonMappable<any>>json).isJsonMappableInstance) return json;

        var cached = this.repositoryService.getFromCache(type, this.repositoryService.determineId(type, json));
        return cached?.retrieved?.pop() ?? new type().map(json, this);
    }

    public retrieveItemWithId<T extends Cacheable<T>>(withFullHydration: boolean, withFullMapping: boolean, id: any, type: new () => T) {
        return this.repositoryService.retrieve<T>(withFullHydration, withFullMapping, type, id)
            .pipe(
                map(jsons => jsons.map(json => this.map(json, type))));
    }


    public retrieveItemsWithIds<T extends Cacheable<T>>(withFullHydration: boolean, withFullMapping: boolean, ids: any[], type: new () => T) {
        return this.repositoryService.retrieve<T>(withFullHydration, withFullMapping, type, ids)
            .pipe(
                map(jsons => jsons.map(json => this.map(json, type))));
    }

    public getOrRetrieveByIds<T extends Cacheable<T>>(withFullHydration: boolean, withFullMapping: boolean, ids: any[], type: new () => T) {
        return this.repositoryService.getOrRetrieve<T>(withFullHydration, withFullMapping, type, ids)
            .pipe(
                map(jsons => jsons.map(json => this.map(json, type))));
    }

}

export interface JsonMappable<T> {
    isJsonMappableInstance: boolean;

    map(jsonBlob: T, jsonMapper: JsonEnricher): T;
    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService);
}

export interface Hydratable<T> extends JsonMappable<T> {
    isHydratableInstance: boolean;
    hasBeenRehydrated: boolean;

    hydrate(jsonShape: T, jsonMapper: JsonEnricher): Observable<T>;
}

export type Cacheable<T> = JsonMappable<T> | Hydratable<T>
