import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { View } from '../base.component';
import { PetColorConfig, PetDTO, PetSpeciesConfig, UserUpload } from 'src/app/dto';
import { AuthService } from 'src/app/services/auth.service';
import { PetService } from 'src/app/services/pet.service';
import { UIStateService } from 'src/app/services/ui-state.service';
import { UserService } from 'src/app/services/user.service';
import { retry, retryWhen, tap, throwError } from 'rxjs';

@Component({
    selector: 'app-profile-pet',
    templateUrl: './profile-pet.component.html',
    styleUrls: ['./profile-pet.component.scss']
})
export class ProfilePetComponent extends View implements OnInit {
    public static route = 'pet';

    @Input() petIdData;

    private petId: number;
    @Input() pet: PetDTO;
    errorState = false;

    constructor(private petService: PetService, private route: ActivatedRoute, authService: AuthService, uiStateService: UIStateService) {
        super(authService, uiStateService);
    }

    ngOnInit(): void {
        this.errorState = false;

        if (this.petIdData && !this.pet) {
            this.petId = this.petIdData;
            this.getPet();
        } else if (this.pet) {
            this.petId = this.pet.Id;
        } else {
            this.route.params.subscribe(params => {
                this.petId = params.petId;
                this.getPet();
            });
        }
    }

    private getPet() {
        this.subscribe(
            this.petService.getPets([this.petId])
                .pipe(
                    tap(pets => {
                        if (!pets || pets.length != 1)
                            throwError(() => "Failed to get pet!");
                    }),
                    retry({ count: 10, delay: 1200 })
                ),
            (pets => {
                this.pet = pets[0];
            }),
            () => this.errorState = true);
    }

    getImagePath(psc: PetSpeciesConfig, pcc: PetColorConfig): string {
        return PetDTO.GetImagePath(psc, pcc);
    }

}
