@if (!isAuthenticated) {
    <div class="splash-screen">
        <div class="welcome-banner">
            <img src="assets/images/tiles/welcome-banner.png" />
        </div>
        <div class="welcome-content">
            <p>Critters is a virtual pet game, community driven and completely free!</p>
            <p>We are now in alpha testing, so we're adding new stuff all the time. Help us build the best virtual pet site!</p>
            <div>
                <h2>Create an account today to join the community and get started with your very own Critter.</h2>
                <a class="btn btn-primary" [routerLink]="['/signup']">Sign up</a> or
                <a class="btn btn-secondary" [routerLink]="" (click)="openModal()">Login!</a>
            </div>
        </div>
    </div>
} @else {
    @if (isAuthenticated && (activeUser$ | async); as user) {
        <h2 class="non-page-header">Welcome to Critters!</h2>
        @if (latestAlert) {
            <app-news-alert-banner [alert]="latestAlert"></app-news-alert-banner>
        }
        <div class="row" appShadowPopover="tutorial1" container="body" #tut1="appShadowPopover">
            @for (pet of activeUserPets$ | async; track pet) {
                <div class="col-md-6 col-lg-3 text-center mb-3">
                    <app-profile-pet [petIdData]="pet.Id" class="card"></app-profile-pet>
                </div>
            }
            <div class="profile col-md-6 col-lg-3 text-center mb-3">
                <div class="card">
                    <div class="card-body">
                        @if (coreAppService.userService.activeUserProfile$ | async; as userProfile) {
                            <img class="profile-image shadow-sm" [src]="userProfile.Image.ImagePath" />
                        } @else {
                            <img class="profile-image shadow-sm" src="assets/images/users/user-icon.png" />
                        }
                        <h3>{{ user.Username }}</h3>
                        <h3><img src="assets/images/icons/icon-critter-coin.svg" class="text-icon invert" />{{ user.Cash }}</h3>
                        <a class="btn btn-primary" [routerLink]="['/inventory/']">Inventory</a>
                        <a class="btn btn-primary" [routerLink]="['/user/' + user.Username]">Profile</a>
                        <a class="btn btn-primary" [routerLink]="['/shopsmanager/']">My Shops</a>
                    </div>
                </div>
            </div>
        </div>
    }
    <!-- TODO Replace with a news component-->
    <app-news-tiles [news]="news$"></app-news-tiles>
}
