import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhaserHttpMethod } from 'angular-to-phaser';
import { Observable, catchError, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../../../dto';
import { AuthService } from '../../auth.service';
import { AbstractRemoteRepository } from './abstract-remote-repository';

@Injectable({
    providedIn: 'root'
})
export class UserProfileRepositoryService extends AbstractRemoteRepository<string, UserProfile> {

    constructor(http: HttpClient,
        authService: AuthService) {

        super(
            (profile: UserProfile) => profile.Username,
            http,
            authService,
            UserProfile);
    }

    retrieveHttpCall(id: string): Observable<UserProfile> {
        return this.http.get<UserProfile>(`${environment.apiUrl}/user/profile${id ? `/${id}` : ''}`, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    retrieveBulkHttpCall(ids: string[]): Observable<UserProfile[]> {
        return this.http.request<UserProfile[]>(PhaserHttpMethod.GET.toString(), `${environment.apiUrl}/user/profile/bulk`,
            { body: ids, ...this.httpOptionsAuthJson() })
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    public updateUserProfile(userProfile: UserProfile): Observable<void> {
        return this.http.put<void>(environment.apiUrl + '/user/profile', userProfile, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError),
            );
    }
}
