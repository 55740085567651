import { AfterViewInit, Component, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationService } from 'src/app/services/application.service';
import { StaticContentService } from 'src/app/services/staticcontent.service';
import { ShadowPopoverDirective } from '../../directives/shadow-popover.directive';
import { PetDTO, StaticContent } from '../../dto';
import { PetService } from '../../services/pet.service';
import { TutorialService } from '../../services/tutorial.service';
import { PageComponent } from '../base.component';
import { LoginModalComponent } from '../login-modal/login-modal.component';

@Component({
    selector: 'app-mainpage',
    templateUrl: './mainpage.component.html',
    styleUrls: ['./mainpage.component.scss']
})
export class MainpageComponent extends PageComponent implements OnInit, AfterViewInit {

    public static route = 'mainpage';
    activeUserPets$: Observable<PetDTO[]>;
    latestAlert: StaticContent;
    news$: Observable<StaticContent[]>;
    @ViewChildren('tut1') tutorial1: QueryList<ShadowPopoverDirective>;



    constructor(protected coreAppService: ApplicationService, private petService: PetService,
        private newsService: StaticContentService, private tutorialService: TutorialService) {

        super(coreAppService.authService, coreAppService.uiStateService);
        this.activeUserPets$ = this.petService.activeUserPets.asObservable();
        this.news$ = this.newsService.allNews$.asObservable();
        this.subscribe(this.newsService.latestAlert$, (val) => { this.latestAlert = val });

    }

    @HostListener('window:resize')
    onResize(): void {
        this.coreAppService.uiStateService.isMobile = window.innerWidth <= 768;
    }

    ngOnInit(): void {
        this.onResize();
    }

    ngAfterViewInit(): void {
        if (!this.tutorialService.tutorialComplete) {
            if (this.tutorial1.length > 0) {
                this.tutorialService.addTutorialSections([this.tutorial1.first]);
            } else {
                this.subscriptions.push(this.tutorial1.changes.subscribe((x: QueryList<ShadowPopoverDirective>) => {
                    this.tutorialService.addTutorialSections([x.first]);
                }));
            }
        }

    }

    openModal() {
        this.coreAppService.modalService.open(LoginModalComponent);
    }
}
