<div class="modal-header">
    <h2 class="modal-title">Login!</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
    @if (this.errorState == true) {
        <div class="invalid">That username/password combination is wrong.</div>
    }
    <form ngNativeValidate (ngSubmit)="submitSignIn(loginForm)" #loginForm="ngForm">
        <div class="form-floating mb-3">
            <input
                class="form-control"
                type="text"
                name="emailAddress"
                placeholder="email or username"
                required
                ngModel
                ngbAutofocus
                id="floatingInput" />
            <label for="floatingInput">Email address or username</label>
        </div>
        <div class="form-floating mb-3">
            <input class="form-control" type="password" name="password" placeholder="password" required ngModel id="floatingPassword" />
            <label for="floatingPassword">Password</label>
        </div>
        <button class="btn btn-primary mb-3" type="submit">Login</button>
    </form>

    <p>Don't have an account? <a [routerLink]="['/signup']" (click)="closeModal()">Sign Up!</a></p>
</div>
