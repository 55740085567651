import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { View } from 'src/app/components/base.component';
import { StaticContent } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
    selector: 'app-news-tiles',
    templateUrl: './news-tiles.component.html',
    styleUrls: ['./news-tiles.component.scss']
})
export class NewsTilesComponent extends View implements OnInit {

    @Input() news: Observable<StaticContent[]>;

    public constructor(private coreAppService: ApplicationService) {
        super(coreAppService.authService, coreAppService.uiStateService);
    }

    ngOnInit(): void {
    }

}
