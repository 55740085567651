/* eslint-disable @typescript-eslint/naming-convention */

import { ContentType, DirectActionRecipientType, GameType, GenericSelectorTargets, InteractionType, ItemFunctionality, LocationType, NotificationType, PetUpdateType, QuestTaskStatusType, SignalType, UIContextAction } from "angular-to-phaser";
import { forkJoin, map, Observable, of, tap } from "rxjs";
import { InventoryService } from "./services/inventory.service";
import { Hydratable, JsonEnricher, JsonMappable } from "./services/jsonmapper.service";
import { PetService } from "./services/pet.service";
import { UnifiedRepositoryGatewayService } from "./services/repository/unified-repository-gateway.service";
import { UserService } from "./services/user.service";
import { Utils } from "./utils";


export class User implements JsonMappable<User> {
    isJsonMappableInstance = true;

    constructor(jsonShape: User = null) { this.map(jsonShape, null); }

    map(jsonShape: User, jsonMapper: JsonEnricher): User {
        if (!jsonShape) { return null; }

        this.Username = jsonShape.Username;
        this.FirstName = jsonShape.FirstName;
        this.LastName = jsonShape.LastName;
        this.EmailAddress = jsonShape.EmailAddress;
        this.Cash = jsonShape.Cash;

        this.Pronouns = jsonShape.Pronouns;
        this.JoinDate = new Date(jsonShape.JoinDate);
        this.Birthdate = Utils.toDateOnly(jsonShape.Birthdate);

        this.City = jsonShape.City;
        this.Country = jsonShape.Country;
        this.Password = jsonShape.Password;
        this.PronounSet = Pronouns.getPronouns(jsonShape.Pronouns);
        return this;
    }

    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService) { /* nothing nested */ }

    public Username: string = null;
    public FirstName: string = null;
    public LastName: string = null;
    public EmailAddress: string = null;
    public Cash: number = null;

    /** Serverside int only, do not use unless you're hella smart (you aint) */
    public Pronouns: number = null;
    public JoinDate: Date = null;
    public Birthdate: Date = new Date();
    public City: string = null;
    public Country: string = null;
    public Password: string = null;

    public PronounSet: Pronouns;
}

export class Pronouns {
    public static THEYTHEM: Pronouns = {
        Id: 0,
        FullName: "they, them",
        Subject: "they",
        Object: "them"
    };
    public static SHEHER = {
        Id: 1,
        FullName: "she, her",
        Subject: "she",
        Object: "her"
    };
    public static HEHIM = {
        Id: 2,
        FullName: "he, him",
        Subject: "he",
        Object: "him"
    };

    /** Correlates to Server enum (User.Pronoun) */
    public Id: number;
    public FullName: string;
    public Subject: string;
    public Object: string;

    public static getPronouns(serverPronounsEnum: number) {
        switch (serverPronounsEnum) {
            case Pronouns.THEYTHEM.Id:
                return Pronouns.THEYTHEM;
            case Pronouns.SHEHER.Id:
                return Pronouns.SHEHER;
            case Pronouns.HEHIM.Id:
                return Pronouns.HEHIM;
            default: return null;
        }
    }

    public static getAll() {
        return [Pronouns.THEYTHEM, Pronouns.SHEHER, Pronouns.HEHIM];
    }
}

export class UserImage implements JsonMappable<UserImage> {
    isJsonMappableInstance = true;

    public constructor() { }

    map(jsonShape: UserImage, jsonMapper: JsonEnricher): UserImage {
        if (!jsonShape) return null;

        this.Id = jsonShape.Id;
        this.Name = jsonShape.Name;
        this.Description = jsonShape.Description;
        this.ImagePath = `assets/images/users/${jsonShape.ImagePath}`;
        this.Locked = jsonShape.Locked;
        return this;
    }

    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService) { /*nothing nested :) */ }


    public Id: string;
    public Name: string;
    public Description: string;
    public ImagePath: string;
    public Locked: boolean;
}

export class UserProfile implements JsonMappable<UserProfile> {
    isJsonMappableInstance = true;

    public constructor() { }

    map(jsonShape: UserProfile, jsonMapper: JsonEnricher): UserProfile {
        if (!jsonShape) return null;

        this.Username = jsonShape.Username;
        this.Description = jsonShape.Description;
        this.Image = jsonMapper.map(jsonShape.Image, UserImage);
        this.LastLogin = new Date(jsonShape.LastLogin);

        return this;
    }

    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService) {
        unifiedRepoGateway.cacheItems(UserImage, this.Image);
    }

    public Username: string;
    public Description: string;
    public LastLogin: Date;
    public Image: UserImage;
}

export class CreateAccountRequest {
    public constructor(public User: User, public Pet: PetDTO, public PersonalityQuizAnswers: QuizAnswer[]) { }
}

export class AuthenticationResponse {
    public AuthToken: string;
    public SessionSummary: AuthenticatedSessionSummary;
}

export class AuthenticatedSessionSummary {
    public User: User;
    public TutorialComplete: boolean;
}

export class PetCreateRequest {
    public constructor(public Pet: PetDTO, public PersonalityQuizAnswers: QuizAnswer[]) { }
}

export class QuizAnswer {
    public constructor(public QuestionId: string, public AnswerId: number) { }
}

export class PetColorConfig {
    public Id: number;
    public Name: string;
    public ImagePath: string;
    public static GetImagePath(psc: PetSpeciesConfig, pcc: PetColorConfig): string {
        return PetDTO.GetImagePath(psc, pcc, null);
    }
}

export class PetSpeciesConfig {
    public Id: number;
    public Name: string;
    public MaxHitPoints: number;
    public Description: string;
    public ImagePath: string;
}

export class PetDTO {
    public constructor(jsonShape: PetDTO) {
        if (!jsonShape) return null;

        this.Id = jsonShape.Id;
        this.Name = jsonShape.Name;
        this.Gender = jsonShape.Gender;
        this.SpeciesId = jsonShape.SpeciesId;
        this.ColorId = jsonShape.ColorId;
        this.IsAbandoned = jsonShape.IsAbandoned;
        this.Level = jsonShape.Level;
        this.HealthPoints = jsonShape.HealthPoints;
        this.BaseHealthPoints = jsonShape.BaseHealthPoints;
        this.FoodLevel = jsonShape.FoodLevel;
        this.Mood = jsonShape.Mood;
        this.MoodImagePath = jsonShape.MoodImagePath;
        this.Species = jsonShape.Species;
        this.Color = jsonShape.Color;

        this.ImagePath = PetDTO.GetImagePath(this.Species, this.Color, this.MoodImagePath);
    }

    public Id: number;
    public Name: string;
    public Gender: string;
    public SpeciesId: number;
    public ColorId: number;
    public IsAbandoned: boolean;
    public Level: number;
    public HealthPoints: number;
    public BaseHealthPoints: number;
    public FoodLevel: string;
    public Mood: string;
    public MoodImagePath: string;

    public Species: PetSpeciesConfig;
    public Color: PetColorConfig;

    public ImagePath: string;

    public UpdateMood(mood: string, moodImagePath: string) {
        this.Mood = mood;
        this.MoodImagePath = moodImagePath;
        this.ImagePath = PetDTO.GetImagePath(this.Species, this.Color, this.MoodImagePath);
    }

    public static GetImagePath(psc: PetSpeciesConfig, pcc: PetColorConfig, moodImagePath: string = 'happy'): string {
        if (pcc) {
            return `assets/images/pets/${psc.ImagePath}_${pcc.ImagePath}_${moodImagePath}.png`;
        } else {
            return `assets/images/pets/${psc.ImagePath}.png`;
        }
    }
}

export class SearchResult {
    constructor(pets: PetDTO[], users: User[]) {
        if (pets)
            this.Pets = pets.map(pet => new PetDTO(pet));
        if (users)
            this.Users = users.map(user => new User(user));
    }

    public Pets: PetDTO[] = [];
    public Users: User[] = [];
}

export class SearchResult2 {

    constructor(pet: PetDTO, user: User) {
        this.Pet = new PetDTO(pet);
        this.User = new User(user);
    }

    public Pet: PetDTO;
    public User: User;
}

export class NotificationTarget {
    public targetingNotification: Notification;
}

export class Friendship extends NotificationTarget implements Hydratable<Friendship> {

    isJsonMappableInstance = true;
    isHydratableInstance = true;
    hasBeenRehydrated = false;

    public SenderUsername: string;
    public RecipientUsername: string;
    public Accepted: boolean;
    public SendDate: Date;
    public FriendUsername: string;

    //local hydrated
    public Profile: UserProfile;

    public constructor() { super(); }

    map(jsonShape: Friendship, jsonMapper: JsonEnricher): Friendship {
        if (!jsonShape) return null;

        this.Accepted = jsonShape.Accepted;
        this.FriendUsername = jsonShape.FriendUsername;
        this.SendDate = new Date(jsonShape.SendDate);
        this.RecipientUsername = jsonShape.RecipientUsername;
        this.SenderUsername = jsonShape.SenderUsername;

        //local only
        this.targetingNotification = null;
        this.Profile = jsonShape.Profile ? new UserProfile().map(jsonShape.Profile, jsonMapper) : null;
        return this;
    }

    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService) {
        unifiedRepoGateway.cacheItems(UserProfile, this.Profile);
    }

    hydrate(jsonShape: Friendship, jsonMapper: JsonEnricher): Observable<Friendship> {
        this.map(jsonShape, jsonMapper);
        if (jsonShape.isHydratableInstance && jsonShape.Profile) {
            this.hasBeenRehydrated = true;
            return of(this);
        } else {
            return jsonMapper.getOrRetrieveByIds(true, true, [jsonShape.FriendUsername], UserProfile)
                .pipe(
                    tap(() => this.hasBeenRehydrated = true),
                    map(profileFetched => {
                        this.Profile = jsonMapper.map(profileFetched[0], UserProfile);
                        return this;
                    }));
        }

    }

    equals(note: Friendship): boolean {
        if (!note) return false;
        return note.FriendUsername.localeCompare(this.FriendUsername) == 0;
    }
}

export class MessageResponse {
    public Channels: Channel[];

    public constructor(jsonShape: MessageResponse, activeUsername: string) {
        if (!jsonShape || !activeUsername) return;

        this.Channels = jsonShape.Channels.map(ch => new Channel(ch, activeUsername))
    }
}

export class SendMessageResponse {
    public MessageId: number;
    public SendDate: Date;

    public constructor(jsonShape: SendMessageResponse) {
        if (!jsonShape) return;

        this.MessageId = jsonShape.MessageId
        this.SendDate = new Date(jsonShape.SendDate);
    }
}

export class Channel {
    constructor(jsonShape: Channel = null, myUsername: string) {
        if (!jsonShape) { return; }

        this.Id = jsonShape.Id;
        this.IsPublic = jsonShape.IsPublic;
        this.Name = jsonShape.Name;
        this.CreatorUsername = jsonShape.CreatorUsername;
        this.CreateDate = new Date(jsonShape.CreateDate);
        this.Usernames = jsonShape.Usernames;
        this.Messages = jsonShape?.Messages?.map(val => new Message(val, myUsername));
        this.MessageCount = jsonShape.MessageCount;
    }
    public Id: number;
    public IsPublic: boolean;
    public Name: string;
    public CreatorUsername: string;
    public CreateDate: Date;
    public Messages: Message[] = [];
    public Usernames: string[] = [];
    public MessageCount: number;

    //local
    public UserProfiles: UserProfile[] = [];
    public ContainsNotification: boolean = false;
}

export class Message extends NotificationTarget {
    constructor(jsonShape: Message = null, myUsername: string) {
        super();
        if (!jsonShape) { return; }

        this.Id = jsonShape.Id;
        this.SenderUsername = jsonShape.SenderUsername;
        this.SendDate = new Date(jsonShape.SendDate);
        this.MessageText = jsonShape.MessageText;
        this.Subject = jsonShape.Subject;
        this.ParentMessageId = jsonShape.ParentMessageId;
        this.ChannelId = jsonShape.ChannelId;
        this.Read = jsonShape.Read;

        this.IsMyMessage = jsonShape.SenderUsername.localeCompare(myUsername) === 0;
        this.targetingNotification = null;
        this.SenderProfile = jsonShape.SenderProfile;
    }

    public Id: number;
    public SenderUsername: string;
    public SendDate: Date;
    public MessageText: string;
    public Subject: string;
    public ParentMessageId: number;
    public ChannelId: number;
    public Read: boolean;

    //local hydrated
    public IsMyMessage: boolean;
    public SenderProfile: UserProfile;
}


export class ItemTypeConfig implements JsonMappable<ItemTypeConfig> {
    isJsonMappableInstance = true;

    public constructor() { }

    map(jsonShape: ItemTypeConfig, jsonMapper: JsonEnricher) {
        if (!jsonShape) return null;
        this.Id = jsonShape.Id;
        this.Name = jsonShape.Name;
        this.Description = jsonShape.Description;
        if (jsonShape.ImagePath) {
            this.ImagePath = jsonShape.ImagePath.includes('assets/images/items') ? jsonShape.ImagePath : `assets/images/items/${jsonShape.ImagePath}`;
        }
        jsonMapper.repositoryService.cacheItems(ItemTypeConfig, this);
        return this;
    }

    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService) { /* nothing nested */ }

    public Id: number;
    public Name: string;
    public Description: string;
    public ImagePath: string;
}

export class InventoryItem implements Hydratable<InventoryItem> {
    isJsonMappableInstance = true;
    isHydratableInstance = true;
    hasBeenRehydrated = false;

    constructor() { }

    hydrate(jsonShape: InventoryItem, jsonMapper: JsonEnricher): Observable<InventoryItem> {
        this.map(jsonShape, jsonMapper);
        if (this.ItemType) {
            if (this.hasBeenRehydrated && this.ItemType.isJsonMappableInstance) {
                return of(this);
            } else {
                jsonMapper.retrieveItemWithId(true, true, this.ItemType.Id, ItemTypeConfig)
                    .pipe(map(itemTypeHydrated => {
                        this.ItemType = itemTypeHydrated[0];
                        this.hasBeenRehydrated = true;
                        return this;
                    }))
            }
        }
        return of(this);
    }

    map(jsonShape: InventoryItem, jsonMapper: JsonEnricher): InventoryItem {
        if (!jsonShape) return null;
        this.Id = jsonShape.Id;
        this.ItemType = jsonMapper.map(jsonShape.ItemType, ItemTypeConfig);
        if (this.ItemType?.isJsonMappableInstance) {
            this.hasBeenRehydrated = true;
        }
        return this;
    }

    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService) {
        unifiedRepoGateway.cacheItems(ItemTypeConfig, this.ItemType);
    }

    public Id: number;
    public ItemType: ItemTypeConfig;
}
export class ItemUse {
    public ItemFunctionality: ItemFunctionality;
    public ItemTypeId: number;
    public Text: string;
}

export class PetResult {
    public PetId: number;


    public ChangedStat: PetUpdateType;

    public InitialState: string;
    public InitialStateValue: number;
    public FinalState: string;
    public FinalStateValue: number;
    public FinalStateMoodImagePath: string

}


export class InventoryResponse implements JsonMappable<InventoryResponse> {
    isJsonMappableInstance = true;

    constructor() { }

    map(jsonShape: InventoryResponse, jsonMapper: JsonEnricher) {
        if (!jsonShape) return null;
        if (jsonShape.Types)
            this.Types = jsonShape.Types.map(t => jsonMapper.map(t, ItemTypeConfig));
        if (jsonShape.Items)
            this.Items = jsonShape.Items.map(i => jsonMapper.map(i, InventoryItem));
        return this;
    }

    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService) {
        unifiedRepoGateway.cacheItems(ItemTypeConfig, ...this.Types);
        unifiedRepoGateway.cacheItems(InventoryItem, ...this.Items)
    }

    public Items: InventoryItem[] = [];
    public Types: ItemTypeConfig[] = [];
}



export class Visitable {
    public constructor(jsonShape: Visitable) {
        if (!jsonShape) return;
        this.Name = jsonShape.Name;
        this.Description = jsonShape.Description;
        this.ImagePath = jsonShape.ImagePath;
        this.DisplaySortOrder = jsonShape.DisplaySortOrder;
        this.OutsideName = jsonShape.OutsideName;
        this.OutsideImagePath = jsonShape.OutsideImagePath;
        this.BackgroundImagePath = jsonShape.BackgroundImagePath;
        this.CssClasses = jsonShape.CssClasses;
        this.CustomCss = jsonShape.CustomCss;
        this.PrecontentCssClasses = jsonShape.PrecontentCssClasses;
        this.PrecontentCustomCss = jsonShape.PrecontentCustomCss;
        if (jsonShape.ParentZone) {
            this.ParentZone = new Zone(jsonShape.ParentZone);
        }
    }
    public Name: string;
    public Description: string;
    public ImagePath: string;
    public DisplaySortOrder: number;
    public LocationType: LocationType;
    public OutsideName: string;
    public OutsideImagePath: string;
    public BackgroundImagePath: string;
    public ParentZone: Zone;
    public CustomCss: string;
    public CssClasses: string[];
    public PrecontentCustomCss: string;
    public PrecontentCssClasses: string[];
}

export class Shop extends Visitable {
    public constructor(jsonShape: Shop) {
        super(jsonShape);
        this.LocationType = LocationType.Shop;
        if (!jsonShape) return;

        this.Id = jsonShape.Id;
        this.OwnerUsername = jsonShape.OwnerUsername;
        if (jsonShape.OwnerNpc) {
            this.OwnerNpc = new Npc(jsonShape.OwnerNpc);
        }

        if (jsonShape.BackgroundImagePath) {
            this.BackgroundImagePath = jsonShape.BackgroundImagePath.includes('assets/images/shops') ? jsonShape.BackgroundImagePath : `assets/images/shops/${jsonShape.BackgroundImagePath}`;
        }
        if (jsonShape.ImagePath) {
            this.ImagePath = jsonShape.ImagePath.includes('assets/images/shops') ? jsonShape.ImagePath : `assets/images/shops/${jsonShape.ImagePath}`;
        }
        if (jsonShape.OutsideImagePath) {
            this.OutsideImagePath = jsonShape.OutsideImagePath.includes('assets/images/shops') ? jsonShape.OutsideImagePath : `assets/images/shops/${jsonShape.OutsideImagePath}`;
        }
    }

    public Id: number;
    public OwnerUsername: string;
    public OwnerNpc: Npc;

    public Items: ShopItem[] = [];//client side only
}

export class ShopItem extends InventoryItem implements JsonMappable<ShopItem> {
    public ShopId: number;
    public Price: number;
    public ShopDescription: string;

    constructor() { super(); }


    map(jsonShape: ShopItem, jsonMapper: JsonEnricher) {
        if (!jsonShape) return null;

        this.ShopId = jsonShape.ShopId;
        this.ShopDescription = jsonShape.ShopDescription;
        this.Price = jsonShape.Price;
        super.map(jsonShape, jsonMapper);
        return this;
    }

}

export class ShopStockResponse implements JsonMappable<ShopStockResponse> {
    isJsonMappableInstance = true;

    public constructor() { }

    map(jsonShape: ShopStockResponse, jsonMapper: JsonEnricher) {
        if (!jsonShape) return null;


        if (jsonShape.Types)
            this.Types = jsonShape.Types.map(t => jsonMapper.map(t, ItemTypeConfig));

        if (jsonShape.Items)
            this.Items = jsonShape.Items.map(i => jsonMapper.map(i, ShopItem));
        return this;
    }

    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService) {
        unifiedRepoGateway.cacheItems(ShopItem, ...this.Items);
        unifiedRepoGateway.cacheItems(ItemTypeConfig, ...this.Types);
    }

    public Items: ShopItem[] = [];
    public Types: ItemTypeConfig[] = [];
}

export class Zone extends Visitable {
    public constructor(jsonShape: Zone) {
        super(jsonShape);
        this.LocationType = LocationType.Zone;
        this.Interactive = jsonShape.Interactive ?? false
        if (!jsonShape) return;

        this.ExternalId = jsonShape.ExternalId;
        this.OwnerNpcExternalId = jsonShape.OwnerNpcExternalId;
        if (jsonShape.Npcs)
            this.Npcs = jsonShape.Npcs.map(npc => new Npc(npc)).sort(n => n.DisplaySortOrder);
        if (jsonShape.Zones)
            this.Zones = jsonShape.Zones.map(zone => new Zone(zone)).sort(n => n.DisplaySortOrder);
        if (jsonShape.Shops)
            this.Shops = jsonShape.Shops.map(shop => new Shop(shop));
        this.AllLocations = (<Visitable[]>this.Zones).concat(...this.Shops).sort(l => l.DisplaySortOrder);

        let imagePath = jsonShape.OwnerNpcExternalId ? Npc.IMAGE_PATH : Zone.IMAGE_PATH; //used for zones like the World Pizza Organization, who behave like/are also NPCs
        if (jsonShape.BackgroundImagePath) {
            this.BackgroundImagePath = jsonShape.BackgroundImagePath.includes(imagePath) ? jsonShape.BackgroundImagePath : `${imagePath}/${jsonShape.BackgroundImagePath}`;
        }
        if (jsonShape.ImagePath) {
            this.ImagePath = jsonShape.ImagePath.includes(imagePath) ? jsonShape.ImagePath : `${imagePath}/${jsonShape.ImagePath}`;
        }
        if (jsonShape.OutsideImagePath) {
            this.OutsideImagePath = jsonShape.OutsideImagePath.includes(imagePath) ? jsonShape.OutsideImagePath : `${imagePath}/${jsonShape.OutsideImagePath}`;
        }
    }

    public static IMAGE_PATH = 'assets/images/zones';

    public ExternalId: string;
    public OwnerNpcExternalId: string;
    public Interactive: boolean;
    public Npcs: Npc[] = [];
    public Zones: Zone[] = [];
    public Shops: Shop[] = [];
    public AllLocations: Visitable[] = [];
}

export class Npc extends Visitable {
    public constructor(jsonShape: Npc) {
        super(jsonShape);
        this.LocationType = LocationType.Npc;
        if (!jsonShape) return;
        this.ExternalId = jsonShape.ExternalId;

        if (jsonShape.BackgroundImagePath) {
            this.BackgroundImagePath = jsonShape.BackgroundImagePath.includes(Npc.IMAGE_PATH) ? jsonShape.BackgroundImagePath : `${Npc.IMAGE_PATH}/${jsonShape.BackgroundImagePath}`;
        }
        if (jsonShape.ImagePath) {
            this.ImagePath = jsonShape.ImagePath.includes(Npc.IMAGE_PATH) ? jsonShape.ImagePath : `${Npc.IMAGE_PATH}/${jsonShape.ImagePath}`;
        }
        if (jsonShape.OutsideImagePath) {
            this.OutsideImagePath = jsonShape.OutsideImagePath.includes(Npc.IMAGE_PATH) ? jsonShape.OutsideImagePath : `${Npc.IMAGE_PATH}/${jsonShape.OutsideImagePath}`;
        }
    }
    public static IMAGE_PATH = 'assets/images/npcs';


    public ExternalId: string;
}

export class DisplayElement {
    public constructor(jsonShape: DisplayElement) {
        if (!jsonShape) return;
        this.CustomCss = jsonShape.CustomCss;
        this.DisplayMetadata = jsonShape.DisplayMetadata;
        this.ContextSpecificData = jsonShape.ContextSpecificData
        this.LottieId = jsonShape.LottieId;
    }
    public CustomCss: string;
    public DisplayMetadata: DisplayMetadata;
    public ContextSpecificData: any;
    public LottieId: string;
}


export class GameStateResponse extends DisplayElement {
    public constructor(jsonShape: GameStateResponse, jsonMapper: JsonEnricher) {
        super(jsonShape)
        if (!jsonShape) return;
        this.Messages = jsonShape.Messages;
        this.PetChanges = jsonShape.PetChanges;
        this.PlayerStateUpdate = new PlayerStateUpdate(jsonShape.PlayerStateUpdate, jsonMapper);
        this.BackgroundPlayerStateUpdate = new PlayerStateUpdate(jsonShape.BackgroundPlayerStateUpdate, jsonMapper);

    }
    public Messages: string[];
    public PetChanges: PetResult[];
    public PlayerStateUpdate: PlayerStateUpdate;
    public BackgroundPlayerStateUpdate: PlayerStateUpdate;


    /**TODO consider replacing with observable or other event, listened to in all services */
    public static distributeGameStateToServices(gameStateUpdate: GameStateResponse, inventoryService: InventoryService, userService: UserService, petService: PetService): void {
        if (gameStateUpdate.PlayerStateUpdate) {
            inventoryService.insertItems(...gameStateUpdate.PlayerStateUpdate.AddedItems);
            inventoryService.removeItems(...gameStateUpdate.PlayerStateUpdate.RemovedItems);
            userService.updateUserCash(gameStateUpdate.PlayerStateUpdate.CashChangedAmount);
        }
        if (gameStateUpdate.BackgroundPlayerStateUpdate) {
            inventoryService.insertItems(...gameStateUpdate.BackgroundPlayerStateUpdate.AddedItems);
            inventoryService.removeItems(...gameStateUpdate.BackgroundPlayerStateUpdate.RemovedItems);
            userService.updateUserCash(gameStateUpdate.BackgroundPlayerStateUpdate.CashChangedAmount);
        }

        if (gameStateUpdate.PetChanges) {
            gameStateUpdate.PetChanges?.forEach(pc => {
                petService.updatePet(pc);
            });
        }
    }

}


export class Interaction extends GameStateResponse {
    public constructor(jsonShape: Interaction, jsonMapper: JsonEnricher) {
        super(jsonShape, jsonMapper);
        if (!jsonShape) return;
        this.InteractionType = jsonShape.InteractionType ?? InteractionType.Interaction;
        this.Title = jsonShape.Title;
        this.Message = jsonShape.Message;
        this.ImagePath = jsonShape.ImagePath;
        this.Responses = jsonShape.Responses;

        if (jsonShape.Quests)
            this.Quests = jsonShape.Quests.map(q => new QuestPreview(q, jsonMapper));

        if (jsonShape.Responses)
            this.Responses = jsonShape.Responses.map(r => new InteractionReply(r, jsonMapper));
    }

    public interactorDisabled: boolean = false;

    public InteractionType: InteractionType;
    public Title: string;
    public Message: string;
    public ImagePath: string;
    public Quests: QuestPreview[];
    public Responses: InteractionReply[];

    public removeFromNestedQuests(find: Interaction): boolean {
        if (!this.Quests || !this.Quests.length) return false;

        let index = this.Quests.indexOf(<QuestPreview>find); //see if find IS A nested quest
        if (index >= 0) {
            this.Quests.splice(index, 1);
            return true;
        }

        index = this.Quests.findIndex(nestedQuest => nestedQuest => nestedQuest.removeFromNestedQuests(find) || nestedQuest.removeFromNestedResponses(find));// >= 0;

        if (index >= 0) {
            this.Quests.splice(index, 1);
            return true;
        }

        return false;
    }

    public replaceInNestedQuests(find: Interaction, replacement: Interaction): boolean {
        if (!this.Quests || !this.Quests.length) return false;

        let index = this.Quests.indexOf(<QuestPreview>find); //see if find IS A nested quest
        if (index >= 0) {
            this.Quests[index] = <QuestPreview>replacement;
            return true;
        }

        index = this.Quests.findIndex(nestedQuest => nestedQuest => nestedQuest.removeFromNestedQuests(find) || nestedQuest.removeFromNestedResponses(find));// >= 0;


        if (index >= 0) {
            this.Quests[index] = <QuestPreview>replacement;
            return true;
        }

        return false;
    }

    public removeFromNestedResponses(find: Interaction): boolean {
        if (!this.Responses || !this.Responses.length) return false;

        let index = this.Responses.findIndex(r => r.hasNextEventInteraction(find)); //see if find IS A Response.Action.NextEvent
        if (index >= 0) {
            this.Responses.splice(index, 1);
            return true;
        }

        return false;
    }

}


export class QuestPreview extends Interaction {
    public constructor(jsonShape: QuestPreview, jsonMapper: JsonEnricher) {
        super(jsonShape, jsonMapper);
        this.InteractionType = InteractionType.QuestPreview;
    }

    public hasResponseInteraction(find: Interaction): boolean {
        if (!this.Responses || !this.Responses.length) return false;
        return this.Responses.findIndex(response => response.Actions.findIndex(a => a.NextEvent == find)) >= 0;
    }
}

export class QuestStatus extends Interaction {
    public constructor(jsonShape: QuestStatus, jsonMapper: JsonEnricher) {
        super(jsonShape, jsonMapper);
        this.Id = jsonShape.Id;
        this.InteractionType = InteractionType.Quest;
        this.Dialogue = jsonShape.Dialogue;
        this.Completed = jsonShape.Completed;
        this.Accepted = jsonShape.Accepted;
        if (jsonShape.QuestTasks) {
            this.QuestTasks = jsonShape.QuestTasks.map(qt => {
                switch (qt.QuestTaskStatusType) {
                    case QuestTaskStatusType.NpcStateTaskStatus: return jsonMapper.map(qt, NpcStateTaskStatus);
                    case QuestTaskStatusType.PossessionTaskStatus: return jsonMapper.map(qt, PossessionTaskStatus);
                    case QuestTaskStatusType.CashBalanceTaskStatus: return jsonMapper.map(qt, CashBalanceTaskStatus);
                }
            });
        }

        if (jsonShape.QuestItemDefinitions) {
            this.QuestItemDefinitions = jsonShape.QuestItemDefinitions.map(qid => jsonMapper.map(qid, ItemTypeConfig));
        }

    }
    public Id: number;
    public Dialogue: string;
    public Completed: boolean;
    public Accepted: boolean;
    public QuestTasks: QuestTaskStatus[];
    public QuestItemDefinitions: ItemTypeConfig[];
}
export abstract class QuestTaskStatus {//} implements JsonMappable<QuestTaskStatus> {
    public constructor() {//jsonShape: QuestTaskStatus){//}, jsonMapper: JsonMapperService) {
        // if (!jsonShape) return null;

        // this.Completable = this.Completable;
    }
    public QuestTaskStatusType: QuestTaskStatusType
    public Completable: boolean;
}

export class NpcStateTaskStatus extends QuestTaskStatus implements JsonMappable<NpcStateTaskStatus> {
    isJsonMappableInstance = true;
    public constructor() { super(); }

    map(jsonShape: NpcStateTaskStatus, jsonMapper: JsonEnricher) {
        if (!jsonShape)
            return null;
        this.Completable = this.Completable;
        this.RequirementsCompleted = jsonShape.RequirementsCompleted;
        this.Description = jsonShape.Description;
        this.QuestTaskStatusType = QuestTaskStatusType.NpcStateTaskStatus;
        return this;
    }

    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService) { /* no nests*/ }

    public RequirementsCompleted: NpcStateStatus[]
    public Description: string;
}

export class NpcStateStatus {
    public Description: string;
    public Completed: boolean;
}

export class PossessionTaskStatus extends QuestTaskStatus implements Hydratable<PossessionTaskStatus> {
    isJsonMappableInstance = true;
    isHydratableInstance = true;
    hasBeenRehydrated = false;

    public constructor() { super(); }

    hydrate(jsonShape: PossessionTaskStatus, jsonMapper: JsonEnricher): Observable<PossessionTaskStatus> {
        this.map(jsonShape, jsonMapper);

        if (this.hasBeenRehydrated && !this.ItemRequirementStatuses.find(irs => !irs.hasBeenRehydrated)) {
            return of(this);
        } else {
            return forkJoin(this.ItemRequirementStatuses
                .filter(irs => !irs.hasBeenRehydrated)
                .map(irs => jsonMapper.hydrate(irs, ItemRequirementStatus)))
                .pipe(
                    map(statuses => {
                        this.ItemRequirementStatuses = statuses;
                        return this;
                    }));
        }
    }

    map(jsonShape: PossessionTaskStatus, jsonMapper: JsonEnricher) {
        if (!jsonShape)
            return null;

        this.Completable = this.Completable;
        if (jsonShape.ItemRequirementStatuses)
            this.ItemRequirementStatuses = jsonShape.ItemRequirementStatuses.map(irs => jsonMapper.map(irs, ItemRequirementStatus));
        this.QuestTaskStatusType = QuestTaskStatusType.PossessionTaskStatus
        return this;
    }

    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService) {
        this.ItemRequirementStatuses.forEach(status => status.cacheNestedElements(unifiedRepoGateway));
    }

    public ItemRequirementStatuses: ItemRequirementStatus[];
}

export class ItemRequirementStatus implements Hydratable<ItemRequirementStatus> {
    isJsonMappableInstance = true;
    isHydratableInstance = true;
    hasBeenRehydrated = false;

    public ItemType: number;
    public RequiredCount: number;
    public Count: number;
    public RequirementSatisfiers: InventoryItem[];

    //enriched locally
    public itemTypeDefinition: ItemTypeConfig;
    public AsItem: InventoryItem;

    public constructor() { }

    map(jsonShape: ItemRequirementStatus, jsonMapper: JsonEnricher) {
        if (!jsonShape)
            return null;

        this.ItemType = jsonShape.ItemType;
        this.RequiredCount = jsonShape.RequiredCount;
        this.Count = jsonShape.Count;
        if (jsonShape.itemTypeDefinition)
            this.itemTypeDefinition = jsonShape.itemTypeDefinition?.isJsonMappableInstance ? jsonShape.itemTypeDefinition : jsonMapper.map(jsonShape.itemTypeDefinition, ItemTypeConfig);

        if (jsonShape.RequirementSatisfiers)
            this.RequirementSatisfiers = jsonShape.RequirementSatisfiers.map(item => jsonMapper.map(item, InventoryItem));

        this.AsItem = <any>{ isHydratableInstance: false, isJsonMappableInstance: false, hasBeenRehydrated: false, ItemType: this.itemTypeDefinition, Id: -1 };
        return this;
    }

    hydrate(jsonShape: ItemRequirementStatus, jsonMapper: JsonEnricher): Observable<ItemRequirementStatus> {
        this.map(jsonShape, jsonMapper);

        if (this.hasBeenRehydrated && this.itemTypeDefinition?.isJsonMappableInstance) {
            return of(this);
        } else {
            return jsonMapper.getOrRetrieveByIds(true, true, [this.ItemType], ItemTypeConfig)
                .pipe(
                    map(itc => {
                        this.itemTypeDefinition = itc[0];
                        this.hasBeenRehydrated = true;
                        this.AsItem = <any>{ isHydratableInstance: false, isJsonMappableInstance: false, hasBeenRehydrated: false, ItemType: this.itemTypeDefinition, Id: -1 };

                        return this;
                    })
                );
        }
    }

    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService) {
        unifiedRepoGateway.cacheItems(ItemTypeConfig, this.itemTypeDefinition);
    }


}

export class CashBalanceTaskStatus extends QuestTaskStatus implements JsonMappable<CashBalanceTaskStatus> {
    public constructor() { super(); }
    isJsonMappableInstance = true;


    map(jsonShape: CashBalanceTaskStatus, jsonMapper: JsonEnricher) {
        if (!jsonShape)
            return null;
        this.CurrentCashBalance = jsonShape.CurrentCashBalance;
        this.RequiredCashBalance = jsonShape.RequiredCashBalance;
        this.QuestTaskStatusType = QuestTaskStatusType.CashBalanceTaskStatus;
        this.Completable = this.Completable;
        return this;
    }

    cacheNestedElements(unifiedRepoGateway: UnifiedRepositoryGatewayService) { /* nothing nested */ }

    public CurrentCashBalance: number;
    public RequiredCashBalance: number;
}

export class InteractionReply extends DisplayElement {
    public constructor(jsonShape: InteractionReply, jsonMapper: JsonEnricher) {
        super(jsonShape);
        if (!jsonShape) return;


        this.OptionText = jsonShape.OptionText;
        this.UIContextAction = jsonShape.UIContextAction;

        if (jsonShape.Actions)
            this.Actions = jsonShape.Actions.map(a => new Action(a, jsonMapper));

    }

    public hasNextEventInteraction(find: Interaction): boolean {
        if (!this.Actions || !this.Actions.length) return false;

        if (this.Actions.findIndex(a => a.NextEvent == find) >= 0)
            return true;

        return this.Actions.findIndex(act => act.NextEvent && (act.NextEvent.removeFromNestedQuests(find) || act.NextEvent.removeFromNestedResponses(find))) >= 0;
    }

    public OptionText: string;
    public Actions: Action[];
    public UIContextAction: UIContextAction;
}

export class Action {
    public constructor(jsonShape: Action, jsonMapper: JsonEnricher) {
        this.DirectAction = jsonShape.DirectAction;
        this.Link = jsonShape.Link;
        if (jsonShape.NextEvent) {
            if (jsonShape.NextEvent.InteractionType == InteractionType.Interaction)
                this.NextEvent = new Interaction(jsonShape.NextEvent, jsonMapper);
            else if (jsonShape.NextEvent.InteractionType == InteractionType.Quest)
                this.NextEvent = new QuestStatus(<QuestStatus>jsonShape.NextEvent, jsonMapper);
            else if (jsonShape.NextEvent.InteractionType == InteractionType.QuestPreview)
                this.NextEvent = new QuestPreview(<QuestPreview>jsonShape.NextEvent, jsonMapper);
        }
    }

    public DirectAction: DirectAction;
    public Link: Link;
    public NextEvent: Interaction;
}

export class DirectAction {
    public ExternalId: string;
    public RecipientType: DirectActionRecipientType;
    public ActionCode: number;
}

export class Link {

    public Path: string; //always populated

    public Verb: string | null; //this is null for Router-navigation links, populated for API Call Links

    public Target: GenericSelectorTargets; //only populated for ServerComponentLinks
    public Title: string;
    public Message: string;
    public InjectedClass: string;

}

export class DisplayMetadata {
    public IsDangerOption: boolean;
    public IsPrimaryOption: boolean;
    public IsFinalOption: boolean;
    public IsLink: boolean;
}

export class Signal {
    public SignalType: SignalType;

    public AlertText: string;

    public constructor(jsonShape: Signal) {
        this.AlertText = jsonShape.AlertText;
        this.SignalType = jsonShape.SignalType;
    }
}

export class NotificationSignal extends Signal {
    public Notification: Notification;
    public constructor(jsonShape: NotificationSignal, myUsername: string, jsonMapperService: JsonEnricher) {
        super(jsonShape);
        if (!jsonShape) { return; }
        this.Notification = new Notification(jsonShape.Notification, myUsername, jsonMapperService);
    }
}

export class Notification {
    Id: string;
    Entity: Friendship | Message;
    NotificationType: NotificationType;
    CreateDate: Date;
    SendDate: Date;
    ReadDate: Date;

    public constructor(jsonShape: Notification, myUsername: string, jsonMapperService: JsonEnricher) {
        if (!jsonShape) { return; }
        this.Id = jsonShape.Id;
        this.NotificationType = jsonShape.NotificationType;
        this.CreateDate = new Date(jsonShape.CreateDate);
        this.SendDate = new Date(jsonShape.SendDate);
        if (jsonShape.ReadDate) {
            this.ReadDate = new Date(jsonShape.ReadDate);
        }

        switch (jsonShape.NotificationType) {
            case NotificationType.FriendshipRequest: this.Entity = new Friendship().map(<Friendship>jsonShape.Entity, jsonMapperService); break;
            case NotificationType.PrivateMessage: this.Entity = new Message(<Message>jsonShape.Entity, myUsername); break;
            case NotificationType.MessageBoard: this.Entity = new Message(<Message>jsonShape.Entity, myUsername); break;
            default: this.Entity = null;

        }
    }
}

export class RandomEventNotification extends Signal {
    constructor(jsonShape: RandomEventNotification, jsonMapper: JsonEnricher) {
        super(jsonShape);
        if (!jsonShape) { return; }
        if (jsonShape.PlayerStateUpdate)
            this.PlayerStateUpdate = new PlayerStateUpdate(jsonShape.PlayerStateUpdate, jsonMapper);

        this.ImagePath = jsonShape.ImagePath;
    }
    public PlayerStateUpdate: PlayerStateUpdate;
    public ImagePath: string;
}

export class PlayerStateUpdate {
    constructor(jsonShape: PlayerStateUpdate, jsonMapper: JsonEnricher) {
        if (!jsonShape) { return; }
        this.CashChangedAmount = jsonShape.CashChangedAmount;
        if (jsonShape.AddedItems)
            this.AddedItems = jsonShape.AddedItems.map(ai => jsonMapper.map(ai, InventoryItem));
        if (jsonShape.RemovedItems)
            this.RemovedItems = jsonShape.RemovedItems.map(ri => jsonMapper.map(ri, InventoryItem));
    }

    public AddedItems: InventoryItem[];
    public RemovedItems: InventoryItem[];
    public CashChangedAmount: number | null;
}

export class AchievementAlert extends Signal {
    public Achievement: AchievementConfig;
}

export class GameConfig {
    private static ASSET_LOCATION_PREFIX = 'assets/images/tiles/games/';
    public Id: GameType;
    public Name: string;
    public Description: string;
    public ImagePath: string;
    public constructor(jsonShape: GameConfig) {
        if (!jsonShape) return;
        this.Id = jsonShape.Id;
        this.Name = jsonShape.Name;
        this.Description = jsonShape.Description;
        if (!jsonShape.ImagePath.includes(GameConfig.ASSET_LOCATION_PREFIX)) {
            this.ImagePath = GameConfig.ASSET_LOCATION_PREFIX + jsonShape.ImagePath;
        } else {
            this.ImagePath = jsonShape.ImagePath;
        }
    }
}

export class UserUpload {
    Id: number;
    Username: string;
    SubmitDate: Date;
    Filepath: string;
}



export class StaticContent {

    constructor(jsonShape: StaticContent) {
        this.Id = jsonShape.Id;
        this.ContentType = jsonShape.ContentType;
        if (this.PublishDate)
            this.PublishDate = new Date(jsonShape.PublishDate);
        this.ImagePath = jsonShape.ImagePath;
        this.Title = jsonShape.Title;
        this.Message = jsonShape.Message;
        this.MessageHtml = jsonShape.MessageHtml;
        this.AlertTitle = jsonShape.AlertTitle;
        this.AlertMessage = jsonShape.AlertMessage;
        this.AlertCssClasses = jsonShape.AlertCssClasses;
        this.AlertIconCssClasses = jsonShape.AlertIconCssClasses;
        this.AlertScrollTargetId = jsonShape.AlertScrollTargetId;
        this.AlertDismissable = jsonShape.AlertDismissable;
        this.ContentFilePath = jsonShape.ContentFilePath;
        this.RouterLink = jsonShape.RouterLink;
        this.Tags = jsonShape.Tags;
        if (this.VisibleStartDate)
            this.VisibleStartDate = new Date(jsonShape.VisibleStartDate);
        if (this.VisibleEndDate)
            this.VisibleEndDate = new Date(jsonShape.VisibleEndDate);
        this.Username = jsonShape.Username;
    }

    Id: number;
    ContentType: ContentType;
    PublishDate: Date;
    ImagePath: string;
    Title: string;
    Message: string;
    MessageHtml: string;
    AlertTitle: string;
    AlertMessage: string;
    AlertCssClasses: string;
    AlertIconCssClasses: string;
    AlertScrollTargetId: string;
    AlertDismissable: boolean;
    ContentFilePath: string;
    RouterLink: string;
    Tags: string[];
    VisibleStartDate: Date;
    VisibleEndDate: Date;
    Username: string;
}


export class MultiplayerGameSummary {
    Id: string;
    Players: User[];
    GameType: GameType;
    GameOver: boolean;
    Host: User;
}

export class GameScoreResult {
    CashWon: number;
    RemainingSubmissions: number;
}

export class AchievementConfig {
    public Id: number;
    public Name: string;
    public Description: string;
    public ImagePath: string;

    private static ASSET_LOCATION_PREFIX: string = 'assets/images/achievements/';
    public constructor(jsonShape: AchievementConfig) {
        if (!jsonShape) return;
        this.Id = jsonShape.Id;
        this.Name = jsonShape.Name;
        this.Description = jsonShape.Description;
        if (jsonShape.ImagePath && !jsonShape.ImagePath.includes(AchievementConfig.ASSET_LOCATION_PREFIX)) {
            this.ImagePath = AchievementConfig.ASSET_LOCATION_PREFIX + jsonShape.ImagePath;
        } else {
            this.ImagePath = jsonShape.ImagePath;
        }
    }
}

export class UserArgumentBag {
    ItemIds: number[] = [];
    ShopIds: number[] = [];
}
