@if (!errorState && pet) {
    <div class="pet-details">
        <img class="pet-image" [src]="pet.ImagePath" />
        <b>{{ pet.Name }}</b>
        <div>{{ pet.Color.Name }} {{ pet.Species.Name }}</div>
        <div class="hungerDisplay">{{ pet.FoodLevel }}</div>
        <div class="hungerDisplay">{{ pet.Mood }}</div>
    </div>
} @else {
    @if (errorState) {
        <div>Oh man, this pet went missing!</div>
    } @else {
        Loading...
    }
}
