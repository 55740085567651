import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { View } from 'src/app/components/base.component';
import { AuthService } from 'src/app/services/auth.service';
import { UIStateService } from 'src/app/services/ui-state.service';

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent extends View {

    public errorText: string;
    public errorTitle: string = "Something went wrong...";

    public imagePath: string;

    public linkText: string;
    public linkRouterPath: string;



    constructor(private activeModal: NgbActiveModal, authService: AuthService, uiStateService: UIStateService) {
        super(authService, uiStateService);
    }

    closeModal(): void {
        this.activeModal.close();
    }

    public initialize(errorText: string, errorTitle: string, imagePath: string, linkText: string, linkRouterPath: string) {
        this.errorText = errorText;
        this.errorTitle = errorTitle;
        this.imagePath = imagePath;
        this.linkText = linkText;
        this.linkRouterPath = linkRouterPath;
    }

}
