import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PetUpdateType } from 'angular-to-phaser';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { PetColorConfig, PetCreateRequest, PetDTO, PetResult, PetSpeciesConfig, QuizAnswer } from '../dto';
import { AbstractService } from './abstractservice';
import { AuthService } from './auth.service';


@Injectable({
    providedIn: 'root'
})
export class PetService extends AbstractService {

    public activeUserPets: BehaviorSubject<PetDTO[]> = new BehaviorSubject<PetDTO[]>([]);

    constructor(private http: HttpClient, authService: AuthService) {
        super(authService);

        authService.authenticatedUser$.subscribe(a => {
            if (!(this.activeUserPets.getValue()?.length) && this.authService.isAuthenticated()) {
                this.initializeActiveUserPets();
            }
        });

    }

    public initializeActiveUserPets(): void {
        this.http.get<PetDTO[]>(environment.apiUrl + '/pet/all', this.httpOptionsAuthJson())
            .pipe(
                map(pets => pets.map(retPet => new PetDTO(retPet))))
            .subscribe(p => {
                if (p && p.length) {
                    this.activeUserPets.next(p);
                }
            });
    }

    public getPets(petIds: number[]): Observable<PetDTO[]> {
        if (!petIds || petIds.length === 0) { return of([]); }

        const foundActiveUserPets = this.activeUserPets
            .getValue()
            .filter(activeUserPet => petIds.findIndex(petId => petId === activeUserPet.Id) !== -1);

        petIds = petIds.filter(id => foundActiveUserPets.findIndex(p => p.Id === id) === -1); //remove ones we already have cached for the active user

        if (petIds.length > 0) {
            return this.http.get<PetDTO[]>(environment.apiUrl + `/pet?${petIds.map(id => `id=${id}`).join('&')}`, this.httpOptionsAuthJson())
                .pipe(
                    map(returnValue => returnValue
                        .map(retPet => new PetDTO(retPet))
                        .concat(foundActiveUserPets)));
        } else {
            return of(foundActiveUserPets);
        }
    }

    public getPetsForUser(username: string): Observable<PetDTO[]> {
        return this.http.get<PetDTO[]>(environment.apiUrl + `/pet/all/${username}`, this.httpOptionsAuthJson())
            .pipe(
                map(returnValue => returnValue
                    .map(retPet => new PetDTO(retPet))));
    }

    public getColors(): Observable<PetColorConfig[]> {
        return this.http.get(environment.apiUrl + '/pet/colors/',
            {
                withCredentials: true,
            }).pipe(
                retry(2),
                catchError(this.handleError),
                map((r: any) => r.Colors as PetColorConfig[])
            );
    }

    public getSpecies(): Observable<PetSpeciesConfig[]> {
        return this.http.get(environment.apiUrl + '/pet/species/',
            {
                withCredentials: true,
            }).pipe(
                retry(2),
                catchError(this.handleError),
                map((r: any) => r.Species as PetSpeciesConfig[])
            );
    }

    public createPet(pet: PetDTO, answers: QuizAnswer[]): Observable<PetDTO> {
        return this.http.post<PetDTO>(environment.apiUrl + '/pet/create/', new PetCreateRequest(pet, answers), this.httpOptionsAuthJson())
            .pipe(
                map(retPet => new PetDTO(retPet)),
                tap(petR => {
                    const pets = this.activeUserPets.getValue();
                    if (pets.findIndex(p => p.Id !== petR.Id)) {
                        pets.push(petR);
                        this.activeUserPets.next(pets);
                    }
                }));
    }

    public checkPetName(name: string): Observable<void> {
        return this.http.get<void>(environment.apiUrl + `/pet/valid/name/${name}`, this.httpOptionsAuthJson());
    }

    public updatePet(petChange: PetResult) {
        const pets = this.activeUserPets.getValue();
        const changedPetIdx = pets.findIndex(p => p.Id === petChange.PetId);
        const changedPet = pets[changedPetIdx];
        if (petChange.ChangedStat === PetUpdateType.Food) {
            changedPet.FoodLevel = petChange.FinalState;
        } else if (petChange.ChangedStat === PetUpdateType.Mood) {
            changedPet.Mood = petChange.FinalState;
        }
        pets[changedPetIdx] = changedPet;
        this.activeUserPets.next(pets);
    }
}
