import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NestableAuthenticatedView } from 'src/app/components/base.component';
import { StaticContent } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { StaticContentService } from 'src/app/services/staticcontent.service';

@Component({
    selector: 'app-news-alert-banner',
    templateUrl: './news-alert-banner.component.html',
    styleUrls: ['./news-alert-banner.component.scss']
})
export class NewsAlertBannerComponent extends NestableAuthenticatedView implements OnInit {

    @Input() alert: StaticContent;
    dismissed = null;


    constructor(private coreAppService: ApplicationService, private staticContentService: StaticContentService, router: Router) {
        super(router, null, null, coreAppService.authService, null, coreAppService.uiStateService)
    }

    dismiss() {
        this.staticContentService.dismissAlert(this.alert);
        this.dismissed = this.staticContentService.alertDismissed(this.alert);
    }

    ngOnInit(): void {
        this.dismissed = this.staticContentService.alertDismissed(this.alert);
    }
}
