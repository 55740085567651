export class Utils {

    static toDateOnly(timestamp: Date | string) {
        let now = new Date();
        let timeStampAsStamp: Date;
        if (typeof timestamp === 'string') {  //its a string
            timeStampAsStamp = new Date(<string>timestamp);
        } else {//its a date
            timeStampAsStamp = <Date>timestamp;
        }
        now.setUTCFullYear(timeStampAsStamp.getUTCFullYear());
        now.setMonth(timeStampAsStamp.getUTCMonth());
        now.setDate(timeStampAsStamp.getUTCDate());
        now.setUTCHours(0, 0, 0, 0);
        return now;
    }
}