import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractService } from '../../abstractservice';
import { AuthService } from '../../auth.service';

export abstract class AbstractRemoteRepository<TIdentifier, TEntity> extends AbstractService {

    protected cache: Map<TIdentifier, TEntity>;
    public typeInstance: TEntity;

    constructor(
        public identiferPropertySelector: (item: TEntity) => TIdentifier,
        protected http: HttpClient,
        auth: AuthService,
        private type: new () => TEntity) {

        super(auth);
        this.typeInstance = new type();
    }

    public abstract retrieveHttpCall(id: TIdentifier): Observable<TEntity>;
    public abstract retrieveBulkHttpCall(id: TIdentifier[]): Observable<TEntity[]>;

    public getId(jsonShape: any) {
        return this.identiferPropertySelector(jsonShape);
    }

}
