import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Friendship } from '../../../dto';
import { AuthService } from '../../auth.service';
import { AbstractRemoteRepository } from './abstract-remote-repository';

@Injectable({
    providedIn: 'root'
})
export class FriendshipRepositoryService extends AbstractRemoteRepository<string, Friendship> {

    constructor(http: HttpClient,
        authService: AuthService) {

        super(
            (profile: Friendship) => profile.FriendUsername,
            http,
            authService,
            Friendship);
    }

    retrieveHttpCall(id: string): Observable<Friendship> {
        return this.retrieveBulkHttpCall(null).pipe(map(friends => friends[0]));
    }

    retrieveBulkHttpCall(ids: string[]): Observable<Friendship[]> {
        return this.http.get<Friendship[]>(environment.apiUrl + '/user/friend', this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    public addFriend(friendUsername: string): Observable<Friendship> {
        return this.http.put<Friendship>(environment.apiUrl + '/user/friend/' + friendUsername, null, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    public removeFriend(friendUsername: string): Observable<void> {
        return this.http.delete<void>(environment.apiUrl + '/user/friend/' + friendUsername, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    public retrieveOnlineFriends() {
        return this.http.get<string[]>(environment.apiUrl + '/user/online/friends', this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

}
