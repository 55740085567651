import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhaserHttpMethod } from 'angular-to-phaser';
import { catchError, map, Observable, retry } from 'rxjs';
import { AuthenticationResponse, CreateAccountRequest, SearchResult, User } from 'src/app/dto';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth.service';
import { AbstractRemoteRepository } from './abstract-remote-repository';

@Injectable({
    providedIn: 'root'
})
export class UserRepositoryService extends AbstractRemoteRepository<string, User> {

    constructor(http: HttpClient,
        authService: AuthService) {

        super(
            (user: User) => user.Username,
            http,
            authService,
            User);
    }

    public retrieveHttpCall(username: string): Observable<User> {
        return this.retrieveUsers(username).pipe(map(arr => arr[0]));
    }

    public retrieveBulkHttpCall(usernames: string[]): Observable<User[]> {
        return this.retrieveUsers(...usernames);
    }

    public createUser(request: CreateAccountRequest): Observable<AuthenticationResponse> {
        return this.http.post<AuthenticationResponse>(environment.apiUrl + '/user/create/', request, this.httpOptionsAuthJson())
            .pipe(
                retry(2)
            );
    }

    public updateUser(request: User): Observable<void> {
        return this.http.patch<void>(environment.apiUrl + '/user/update/', request, this.httpOptionsAuthJson())
            .pipe(
                retry(2)
            );
    }

    public searchUsers(userSearchQuery: string): Observable<SearchResult> {
        return this.http.get<SearchResult>(environment.apiUrl + '/search/' + userSearchQuery, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError),
            );
    }

    private retrieveUsers(...usernames: string[]): Observable<User[]> {
        if (!usernames || usernames.length === 1) { //no names = self
            return this.http.get<User>(`${environment.apiUrl}/user/${usernames ? usernames[0] : ''}`, this.httpOptionsAuthJson())
                .pipe(
                    map(user => [user]),
                    retry(2),
                    catchError(this.handleError),
                );
        } else {
            return this.http.request<User[]>(PhaserHttpMethod.GET.toString(), `${environment.apiUrl}/user/bulk`, { body: usernames, ...this.httpOptionsAuthJson() })
                .pipe(
                    retry(2),
                    catchError(this.handleError)
                );
        }
    }

}
