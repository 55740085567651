import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticatedSessionSummary, AuthenticationResponse, User } from './../dto';
import { AbstractService } from './abstractservice';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private http: HttpClient) {
        this.jwtToken = '';
        this.attemptCookieLogin();
    }

    public loginResponse: AuthenticationResponse;
    public jwtToken: string;
    public sessionSummary: AuthenticatedSessionSummary;
    public authenticatedUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    public isAuthenticated(): boolean {
        return this.authenticatedUser$.getValue() != null && this.jwtToken !== null && this.jwtToken.length > 0;
    }

    public loginWithCredentials(userNameOrEmail: string, password: string): Observable<AuthenticationResponse> {
        return this.http.post<AuthenticationResponse>(environment.apiUrl + '/user/login/',
            {
                UsernameEmailAddress: userNameOrEmail,
                Password: password
            },
            {
                withCredentials: true,
                headers: new HttpHeaders(
                    {
                        'Content-Type': 'application/json'
                    })
            }).pipe(
                retry(2),
                tap((data: AuthenticationResponse) => {
                    this.loginResponse = data;
                    this.jwtToken = data.AuthToken;
                    this.sessionSummary = data.SessionSummary;
                    this.authenticatedUser$.next(data.SessionSummary.User);
                })
            );
    }

    public refreshSession(): Observable<AuthenticationResponse> {
        return this.http.post<AuthenticationResponse>(environment.apiUrl + '/user/refreshSession/', null, AbstractService.httpOptionsAuthJson(this.jwtToken)).pipe(
            retry(2),
            tap((data: AuthenticationResponse) => {
                this.loginResponse = data;
                this.jwtToken = data.AuthToken;
                this.sessionSummary = data.SessionSummary;
                this.authenticatedUser$.next(data.SessionSummary.User);
            })
        );
    }

    private attemptCookieLogin(): void {
        this.http.get<AuthenticationResponse>(environment.apiUrl + '/user/autologin',
            {
                withCredentials: true,
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            })
            .pipe(retry(2), catchError((err: HttpErrorResponse, caught) => {
                if (err.status === 400 || err.status === 302) {
                    return EMPTY;
                } else {
                    return throwError(() => err.error);
                }
            }))
            .subscribe(data => {
                if (data) {
                    this.jwtToken = data.AuthToken;
                    this.sessionSummary = data.SessionSummary;
                    this.authenticatedUser$.next(data.SessionSummary.User);
                }
            });
    }

}
